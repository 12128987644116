import React, { useState } from 'react';
import { Card, CardBody, Col, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import cow from '../../assets/Images/Cow icon.png';
import { GoBell, GoPencil } from 'react-icons/go';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';

function BreedingList({ title, cattleData = [], bgcolor,onCardClick }) {

    const [showAll, setShowAll] = useState(false);
    // Function to calculate the difference between two dates
    const calculateDateDifference = (expectedDate) => {
        const today = new Date();  // Current date
        const expectedPregnancyDate = new Date(expectedDate);  // Convert expected_pregnancy string to Date object

        const timeDiff = expectedPregnancyDate - today;  // Difference in milliseconds
        const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));  // Convert milliseconds to days

        return daysDiff;
    };

    const data = showAll ? cattleData : cattleData.slice(0, 4);

    return (
        <div className="w-full h-full p-3 bg-[#FAFAFA] rounded-xl border border-[#E3E3E3] flex flex-col items-start gap-2.5">
            <div className="self-stretch h-full flex flex-col justify-start items-start gap-4">
                <div className="self-stretch flex justify-between items-center">
                    <div className="text-[#414D55] text-sm font-bold leading-[21.6px] font-plus-jakarta-sans">{title}</div>
                    <div className="text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans cursor-pointer" onClick={() => onCardClick()}>See All</div>
                </div>
                <div className="self-stretch h-full flex flex-col justify-start items-start gap-3">
                    {data.length > 0 ? (
                        data.map((item, index) => {
                            const daysUntilPregnancy = calculateDateDifference(item.expected_pregnancy); // Calculate days until expected pregnancy

                            return (
                                <div key={index} className="self-stretch p-2 bg-white rounded-lg border border-[#E3E3E3] flex justify-between items-center cursor-pointer"
                                onClick={() => onCardClick(item)} >
                                    <div className="self-stretch flex justify-start items-start gap-2">
                                        <div className="relative w-10 h-10">
                                            <div style={{ backgroundColor: bgcolor }} className="absolute w-10 h-10 rounded-md" />
                                            <img
                                                className="absolute w-9 h-9 rounded-md top-0 left-0.5"
                                                src={cow}
                                                alt={`Cattle No. ${item.id}`}
                                            />
                                        </div>
                                        <div className="flex flex-col justify-start items-start gap-1">
                                            <div className="self-stretch text-[#0A0B0A] text-sm font-bold leading-[19.2px] font-plus-jakarta-sans capitalize">
                                                {/* Cattle No. {item.id} */}
                                                Cattle No. {item.Cattle.cattle_id}
                                            </div>
                                            <div className="self-stretch text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">
                                                {daysUntilPregnancy < 0
                                                    ? `Pregnancy overdue by ${Math.abs(daysUntilPregnancy)} days`
                                                    : `Expected in ${daysUntilPregnancy} days`}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Action Icons */}
                                    {/* <div className="flex gap-2">
                                        <GoBell className="text-[#0EA5E9]" />
                                        <GoPencil className="text-[#B5B3B3]" />
                                        <PiDotsThreeOutlineVerticalFill className="text-[#B5B3B3]" />
                                    </div> */}
                                </div>
                            );
                        })
                    ) : (
                      <div className="flex justify-center items-center w-full h-full text-center text-[#898483] text-xs font-normal leading-[16.8px]">
                      No Data
                  </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BreedingList;
