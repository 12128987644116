import React from 'react'
import Header from '../Header/Header'
import SideBar from '../Common/SideBar'

function DashBoard() {
  return (
    <div>
      {/* <div>
        <Header/>
        </div>
        <div >
        <SideBar/>
        </div> */}
    </div>
  )
}

export default DashBoard