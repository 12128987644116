import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol';
import Heading from '../Common/Heading';
import Dropdownbtn from '../Common/Dropdownbtn';
import SearchBox from '../Common/SearchBox';
import CommonButton from '../Common/Button';
import { Nav, NavItem, NavLink } from 'reactstrap';
import ReactTable from '../Common/Table';
import cowicon from '../../assets/Images/Cow icon.png'
import Filterbtn from '../Common/Filterbtn';
import RecordInsemination from '../Drawer/RecordInsemination';
import API, { action } from '../../Api';
import RecordInseminationView from '../Drawer/RecordInseminationView';
import TableAccordion from '../Common/TableAccordion';
function InseminationReport() {
    const [searchTerm, setSearchTerm] = useState(""); 
    const [tab, setTab] = useState("1");
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };
    const [record,setRecord] = useState(false);
   const [inseminationList,setInsemenationList]=useState([])
   const [inseminationView,setInseminationView]=useState(false)
   const [details,setDetails] =useState(null)
   const [paginationcount,setPaginationcount]=useState()
   const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const ButtonClick = () =>{ setRecord(!record)
        getInseminationList()
       
    }


    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Insemination Type",
            value: "InseminationType",

        },


        {
            id: 4,
            title: "Insemination Date",
            value: "InseminationDate",


        },
        {
            id: 5,
            title: "Status",
            value: "status",


        },

        {
            id: 6,
            title: "Expected Pregnancy",
            value: "ExpectedPregnancy",


        },
        {
            id: 7,
            title: "Action",
            value: "action",


        },

    ]
    const headingsformobile = [

       
        {
            id: 1,
            title: "Insemination Type",
            value: "InseminationType",

        },


        {
            id: 2,
            title: "Insemination Date",
            value: "InseminationDate",


        },
        {
            id: 3,
            title: "Status",
            value: "status",


        },

        {
            id: 4,
            title: "Expected Pregnancy",
            value: "ExpectedPregnancy",


        },
        
    ]


    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            status: "Heated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            status: "Heated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            status: "Re inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            status: "Re inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            status: "Re inseminated",
            HeatDate: "11-10-2024",
            action: "View Report",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "Diagnose",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "View Report",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            status: "Inseminated",
            HeatDate: "11-10-2024",
            action: "View Report",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            status: "Heated",
            HeatDate: "11-10-2024",
            action: "View Report",
        },
    ];

    const getInseminationList = async (page = 1, pageSize = 10) => {
    const result = await action(API.GET_INSEMINATION, {
        type: "",
        searchKey: "",
        page: page,
        pageCount: pageSize,
    });

    // Log page and result details
    console.log(
        `Page: ${page}, PageSize: ${pageSize}, Result Count: ${result.response.data.length}`,
        result.response.data
    );

    setPaginationcount(result.response.count);
    setInsemenationList(result.response.data);

    const formattedData = result.response.data.map((item, index) => ({
        SlNo: ((page - 1) * pageSize + index + 1).toString().padStart(2, '0'), // Format SlNo
        ID: item.id,
        CattleID: item.Cattle.cattle_id,
        InseminationType: item.insemination_type,
        InseminationDate: item.insemination_date,
        status: item.status,
        ExpectedPregnancy: item.expected_pregnancy,
        description: item.description,
        action: item.status === 'failed' ? 'View' : 'Diagnose',
    }));

    setInsemenationList(formattedData);
    console.log(inseminationList,"inseminationList")
};

    useEffect(() => {
        getInseminationList()

    }, [pageSize,currentPage,])
    const handleAction=(userId) => {
        console.log(userId,"kkk");
        
        setDetails(userId)
        setInseminationView(!inseminationView)
        getInseminationList()
    }

    




    const filteredData = (inseminationList || []).filter(user => {
        const CattleID = user.CattleID || ""; // Prevent null error
        const InseminationType = user.InseminationType || ""; // Prevent null error
        const InseminationDate =user.InseminationDate || "";
        const status =user.status || "";
        const ExpectedPregnancy =user.ExpectedPregnancy || "";
        return (
            CattleID.toLowerCase().includes(searchTerm.toLowerCase()) ||
            InseminationDate.includes(searchTerm) ||
            InseminationType.toLowerCase().includes(searchTerm.toLowerCase())||
            status.toLowerCase().includes(searchTerm.toLowerCase())||
            ExpectedPregnancy.includes(searchTerm) 
        )
        
    });
    

    return (
        <div style={{ backgroundColor: "#FAFAFA", minHeight: "calc(100vh - 135px)" }}>
            <FlexCol>
                <div className="d-flex flex-col sm:flex-row  justify-content-between ">
                    <div className='d-flex space-x-2'>
                        <Heading title='Insemination Report' />
                        {/* <Dropdownbtn title='Today' style={{backgroundColor:"#FAFAFA",color:"#5A5555",border: '1px #CCCBCB solid'}} /> */}
                    </div>
                    <div className="d-flex space-x-5">
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]'value={searchTerm} 
                                onSearch={setSearchTerm}    />
                        <Filterbtn />
                        <CommonButton title="New Insemination" onClick={ButtonClick} />

                    </div>
                </div>
                {/* <Cards />
                <Row className="g-3">
                    <Col md={7} >
                        <Graph title='Sick Cattle Statistics' />

                    </Col>
                    <Col md={5} >
                        <PieChart title="Sick Cattle Distribution" />

                    </Col>

                </Row> */}
                <Nav pills className="nav-success ">
                    {["All", "Inseminated", "Re inseminated", "Failed"].map((tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                {isMobile ? (
                    <>
                   {tab === "1" && <TableAccordion headings={headingsformobile} cattleData={filteredData} handleAction={handleAction} />}
                   {tab === "2" && <TableAccordion headings={headingsformobile} cattleData={filteredData.filter(user => user.status === "inseminated")} handleAction={handleAction} />}
                   {tab === "3" && <TableAccordion headings={headingsformobile} cattleData={filteredData.filter(user => user.status === "re_inseminated")} handleAction={handleAction} />}
                   {tab === "4" && <TableAccordion headings={headingsformobile} cattleData={filteredData.filter(user => user.status === "failed")} handleAction={handleAction} />}
                   </>
                ) : (
                   
               <>
                {tab === "1" && <ReactTable headings={headings} data={filteredData} headingcolor="#D9D9D9" handleAction={handleAction} pagination={paginationcount} fetchData={getInseminationList}/>}
                {tab === "2" && <ReactTable headings={headings} data={filteredData.filter(user => user.status === "inseminated")} headingcolor="#D9D9D9" handleAction={handleAction} />}
                {tab === "3" && <ReactTable headings={headings} data={filteredData.filter(user => user.status === "re_inseminated")} headingcolor="#D9D9D9" handleAction={handleAction} />}
                {tab === "4" && <ReactTable headings={headings} data={filteredData.filter(user=> user.status === "failed")} headingcolor="#D9D9D9" handleAction={handleAction} />}
                </>
            )}
            </FlexCol>
            <RecordInsemination isOpen={record} toggle={ButtonClick}/>
           <RecordInseminationView isOpen={inseminationView} toggle={handleAction} selectedData={details} refresh={getInseminationList}/>


        </div>
    )
}

export default InseminationReport