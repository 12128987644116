import { Flex } from "antd";
import React from "react";
import { HiMiniStar } from "react-icons/hi2";

export default function Heading({
    className,
    title = "",
    description = "",
    required = false,
    font = "20px",
}) {
    return (
        <div
            className={`flex flex-col  justify-center gap-0.5 ${className}`}
        >
            <div className="flex">
                {/* previous code */}
                <h1 className={`md:text-2xl sm:text-xl text-xl font-semibold  2xl:text-[32px] text-[#09090B] ${className}`}>{title}</h1>
                {/* <h1
          className={`text-sm font-semibold text-black 2xl:text-base dark:text-white flex items-center gap-2`}
        >
          {title}
        </h1> */}
                {required && <HiMiniStar className="text-[10px] text-rose-600" />}
            </div>
            <p className="text-[12px] 2xl:text-sm leading-normal font-semibold text-grey  !font-medium overflow-hidden text-ellipsis">{description}</p>
        </div>
    );
}
