import React from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import Cards from '../Common/Cards'
import CommonGraph from '../Common/CommonGraph'
import Commonbarchart from '../Common/Commonbarchart'
import { Col, Row } from 'reactstrap'
import Reportsbarchart from '../Common/Reportsbarchart'
import { useNavigate } from 'react-router'

function ReportsMonitoring() {
    const navigate = useNavigate()
    const data = [
        {
            id: 1,
            cardColor: "primary",
            label: "Inseminated",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+16.24",
            counter: "100",
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Pregnant",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            percentage: "-3.57",
            counter: "48",
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },

    ]

    const healthreport = () => {
        navigate('/cattleHealthReport')
    }
    const milkReport = () => {
        navigate('/milkingReport')
    }
    const pregnancyReport = () => {
        navigate('/cattleBreedingReport')
    }
    const feedingReport = () => {
        navigate('/cattleFeedingReport')
    }
    return (
        <div style={{ backgroundColor: "#FAFAFA" }}>
            <Row className="gx-4 gy-4">
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Pregnancy Analytics' />

                            </div>
                            <Cards data={data} />
                            <Reportsbarchart title={"Pregnancy Report"} subtitle={"Monthly overview of pregnancies to manage herd reproduction."} bgcolor="#FDBF26" btnbgcolor="#FDBF26" btntitle="View Report" titleColor="#FDBF26" handleclick={pregnancyReport} />
                        </FlexCol>
                    </div>
                </Col>
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Milking Analytics' />

                            </div>
                            <Cards data={data} />
                            <Reportsbarchart title={"Milking Report"} subtitle={"Track monthly milk production trends to monitor output efficiency"} bgcolor="#0EA5E9" btnbgcolor="#0EA5E9" btntitle="View Report" titleColor="#0EA5E9" handleclick={milkReport} />
                        </FlexCol>
                    </div>
                </Col>
            </Row>
            <Row className="gx-4 gy-4 mt-4">
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Health Analytics' />

                            </div>
                            <Cards data={data} />
                            <Reportsbarchart title={"Health Report"} subtitle={"Track Feeding to keep your herd protected."} bgcolor="#E4626F" btnbgcolor="#E4626F" btntitle="View Report" titleColor="#E4626F" handleclick={healthreport} />
                        </FlexCol>
                    </div>
                </Col>
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Feeding Analytics' />

                            </div>
                            <Cards data={data} />
                            <Reportsbarchart title={"Feeding Report"} subtitle={"Track Feeding to keep your herd protected."} bgcolor="#16A34A" btnbgcolor="#16A34A" btntitle="View Report" titleColor="#16A34A" handleclick={feedingReport} />
                        </FlexCol>
                    </div>
                </Col>
            </Row>
        </div>


    )
}

export default ReportsMonitoring