import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SimpleBar from "simplebar-react";

const MyTask = ({ heading = 'My Task', data = [], btntitle = 'Add Task', onclick, seeAllLink = "#" }) => {
    // Example task data (this is unused if data prop is passed)
    const tasks = [

    ];

    // Use the provided data or fall back to example tasks if none is passed
    const DataToDisplay = data.length > 0 ? data : tasks;

    // State to track the checkbox selections
    const [selectedTasks, setSelectedTasks] = useState([]);

    const handleCheckboxChange = (taskId) => {
        setSelectedTasks(prevState => {
            if (prevState.includes(taskId)) {
                return prevState.filter(id => id !== taskId);
            } else {
                return [...prevState, taskId];
            }
        });
    };

    const remainingTasks = DataToDisplay.length - selectedTasks.length;

    return (
        <React.Fragment>
            <Col>
                <div className="2xl:p-6 p-2 bg-white rounded-xl border border-gray-300">
                    <div className="align-items-center d-flex p-3">
                        <h4 className="card-title mb-0 flex-grow-1 2xl:text-[20px] text-[14px]" style={{
                            color: "#495057",
                            fontFamily: "Plus Jakarta Sans",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal"
                        }}>
                            {heading}
                        </h4>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle tag="a" className="text-reset dropdown-btn 2xl:text-[18px] text-[14px]" style={{ textDecoration: 'none' }} role="button">
                                    <span style={{ color: "#898483", fontFamily: "Plus Jakarta Sans" }}>Today<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                {/* <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem>Edit</DropdownItem>
                                    <DropdownItem>Remove</DropdownItem>
                                </DropdownMenu> */}
                            </UncontrolledDropdown>
                        </div>
                    </div>

                    <CardBody className="p-0">
                        <div className="align-items-center p-3 justify-content-between d-flex">
                            <button type="button" onClick={onclick} className="btn btn-sm add-btn" style={{ padding: '0.15rem 0.3rem', fontSize: '10px', fontFamily: 'Plus Jakarta Sans', backgroundColor: '#16A34A', color: "white" }}>
                                <i className="ri-add-line align-middle me-1 add-btn"></i> {btntitle}
                            </button>
                        </div>

                        <SimpleBar style={{ maxHeight: "250px", minHeight: "250px" }}>
                            <ul className="list-group list-group-flush border-dashed px-3">
                                {DataToDisplay.length > 0 ? (
                                    DataToDisplay.map((item, index) => (
                                        <div className="d-flex align-items-start" key={index}>
                                            {/* <div className="form-check ps-0 flex-shrink-0">
                                            <input 
                                                type="checkbox" 
                                                className="form-check-input ms-0" 
                                                id={item.forId} 
                                                checked={selectedTasks.includes(item.id)} 
                                                onChange={() => handleCheckboxChange(item.id)} 
                                            />
                                        </div> */}
                                            <div className="flex-grow-1">
                                                <label className="form-check-label 2xl:mb-4 ps-2 2xl:ps-4 2xl:text-[18px] text-[12px] capitalize" style={{
                                                    color: "#212529",
                                                    fontFamily: "Plus Jakarta Sans",
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    lineHeight: "120%"
                                                }} htmlFor={item.forId}>{item.text}</label>
                                            </div>
                                            <div className="flex-shrink-0 ms-2">
                                                <p className="text-muted fs-12 mb-0 2xl:text-[18px] text-[12px] " style={{
                                                    color: "#878A99",
                                                    fontFamily: "Plus Jakarta Sans",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    lineHeight: "120%"
                                                }}>{item.date}</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                        <p style={{
                                            color: "#878A99",
                                            fontFamily: "Plus Jakarta Sans",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "120%"
                                        }}>No Data</p>
                                    </div>
                                )}
                            </ul>
                        </SimpleBar>

                        <div className="p-3 pt-2 text-right">
                            <Link to={seeAllLink} className="text-decoration-none 2xl:text-[18px] text-[14px]" style={{
                                fontFamily: "Plus Jakarta Sans",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                color: "#5AC5F5"
                            }}>See All</Link>
                        </div>
                    </CardBody>
                </div>
            </Col>
        </React.Fragment>
    );
};

export default MyTask;
