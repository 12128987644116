import React, { useState } from "react";

import '../../assets/css/style.css'
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import { DatePicker } from "antd";

const DateFilter = ({
    change = () => {},
    className,
    pickerType = "date",
    dateFormat = "YYYY-MM-DD",
    value = null,
    title = "",
    description = "",
    error = "",
    required = false,
    placeholder = "",
    placement = "",
    joiningDate,
    defaultPickerValue = null,
    dateofBirth = false,
    hideYear = false,
    fromDate,
    maxdate = false,
    disabled = false,
   disableBeforeDate = ''
}) => {
//   const [selectedDate, setSelectedDate] = useState(null);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     console.log("Selected Date:", date); // You can replace this with an API call or other actions
//   };
const isSmallScreen = useMediaQuery({ maxWidth: 1439 });
  return (
    <div className="date-filter">
      {/* <label className="block mb-2 text-sm font-medium text-gray-700">
        Select Date
      </label> */}
      <DatePicker
      value={value ? dayjs(value, dateFormat) : null}
      picker={pickerType}
      format={dateFormat}
      onChange={(date, dateString) => {
        change(dateString);
      }}
      status={error ? "error" : ""}
      // placement={placement}  // if give placement the dropdown date are displaying in the other side of the window.
      size={isSmallScreen ? "default" : "large"}
    //   className={`w-full border rounded-lg ${error ? "border-rose-400" : ""}`}
      style={
        error
          ? {
              boxShadow:
                "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }
          : {}
      }
      
      placeholder={placeholder}
      
      disabled={disabled} 
      
    />
    </div>
  );
};

export default DateFilter;
