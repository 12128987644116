import React, { useEffect, useState } from 'react';
import FlexCol from '../Common/FlexCol';
import Heading from '../Common/Heading';
import CommonButton from '../Common/Button';
import Dropdownbtn from '../Common/Dropdownbtn';
import Cards from '../Common/Cards';
import { Col, Row } from 'reactstrap';
import Commonbarchart from '../Common/Commonbarchart';
import Insemination_report from '../../assets/Images/Insemination report.svg';
import BreedingList from './BreedingList';
import frame2 from '../../assets/Images/Frame2.svg';
import DeliveryReport from './DeliveryReport';
import InseminationReport from './InseminationReport';
import API, { action } from '../../Api';
import { Navigate, useNavigate } from 'react-router';
import RecordDeliveryForm from '../Drawer/RecordDeliveryForm';

function Breeding() {
  const [Deliveryreport, setDeliveryreport] = useState(false);
  const [insemination, setInsemination] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [inseminationreport, setInseminationreport] = useState([]);
  const [inseminationreportChart, setInseminationreportChart] = useState([]);
  const [deliveryreportchart, setDeliveryreportchart] = useState([]);
  const [upcommingList, setUpcommingList] = useState([]);

  const carddata = [
    {
      id: 1,
      cardColor: 'primary',
      label: 'Pregnant Cows',
      badge: 'ri-arrow-right-up-line',
      badgeClass: 'success',
      percentage: '+16.24',
      counter: inseminationreport.total_pregnant_cattles,
      link: 'View net earnings',
      bgcolor: 'primary',
      icon: 'bx bx-dollar-circle',
      decimals: 2,
      prefix: '$',
      suffix: 'k',
    },
    {
      id: 2,
      cardColor: 'secondary',
      label: 'Due for Delivery',
      badge: 'ri-arrow-right-down-line',
      badgeClass: 'danger',
      percentage: '-3.57',
      counter: inseminationreport.due_to_delivery,
      link: 'View all orders',
      bgcolor: 'primary',
      icon: 'bx bx-shopping-bag',
      decimals: 0,
      prefix: '',
      separator: ',',
      suffix: '',
    },
    {
      id: 3,
      cardColor: 'success',
      label: 'Successful Inseminations',
      badge: 'ri-arrow-right-up-line',
      badgeClass: 'success',
      percentage: '+29.08',
      counter: inseminationreport.successfull_insemination,
      link: 'See details',
      bgcolor: 'primary',
      icon: 'bx bx-user-circle',
      decimals: 2,
      prefix: '',
      suffix: 'M',
    },
    {
      id: 4,
      cardColor: 'info',
      label: 'Delivered Cows',
      badgeClass: 'muted',
      percentage: '+0.00',
      counter: inseminationreport.cows_in_heat || 0,
      link: 'Withdraw money',
      bgcolor: 'primary',
      icon: 'bx bx-wallet',
      decimals: 2,
      prefix: '$',
      suffix: 'k',
    },
  ];

  const handleclick = () => {
    setDeliveryreport(!Deliveryreport)
  };

  const handlebtnclick = () => {
    navigate('/breeding/inseminationReport');
  };

  const getPendingList = async () => {
    const result = await action(API.PENDING_CATTLE_LIST, {
      page: 1,
      pageCount: 10,
    });
    console.log(result.response.data, 'get pending list');
    setPendingList(result.response.data);
  };

  useEffect(() => {
    getPendingList();
  }, []);

  const getInseminationReport = async () => {
    const result = await action(API.INSEMINATION_REPORT, {
      year: '2024',
    });
    console.log(result.response.data, 'get insemination list');
    setInseminationreport(result.response.data);
    setInseminationreportChart(result.response.data.monthly_report)
  };

  useEffect(() => {
    getInseminationReport();
  }, []);

  // Transform the insemination report data only when it is available
  const transformedData = Array.isArray(inseminationreportChart) && inseminationreportChart.length > 0
    ? inseminationreportChart.map((item) => ({
      month: item.month,
      pregnant: item.pregnant,
      inseminated: item.inseminated,
      re_inseminated: item.re_inseminated,
    }))
    : [];
  const navigate = useNavigate();
  const cardClick = (item) => {
    if (item.label === 'Pregnant Cows') {
      navigate('/breeding/pregnancyReport');
    }
    else if (item.label === 'Due for Delivery') {
      navigate('/breeding/deliveryReport');
    }

    else if (item.label === 'Successful Inseminations') {
      navigate('/breeding/inseminationReport');
    }
    else {
      navigate("/breeding/deliveredReport")
    }
  }

  const oncardclick = () => {
    navigate("/breeding/pregnancyReport")
  }


  const getDeliveryReport = async () => {
    const result = await action(API.DELIVERY_REPORT, {
      year: '2024',
    });
    console.log(result.response.data, 'get insemination list');
    setDeliveryreportchart(result.response.data);
  };

  useEffect(() => {
    getDeliveryReport();
  }, []);

  const transformedDeliveryReportData = Array.isArray(deliveryreportchart) && deliveryreportchart.length > 0
    ? deliveryreportchart.map((item) => ({
      month: item.month,
      Calf: item.Calf,
      Heifer: item.Heifer,
      Bull: item.Bull,
    }))
    : [];


  const getUpcommingList = async () => {
    const result = await action(API.UPCOMMING_CATTLE_LIST, {
      page: 1,
      pageCount: 10,
    });
    console.log(result.response.data, 'get upcomming list');
    setUpcommingList(result.response.data);
  };

  useEffect(() => {
    getUpcommingList();
  }, []);



  return (
    <div style={{ backgroundColor: '#FAFAFA', minHeight: "calc(100vh - 135px)" }}>
      {(!insemination) && (
        <FlexCol>
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="d-flex space-x-2">
              <Heading title="Breeding Statistics" />
              {/* <Dropdownbtn title="Today" style={{ backgroundColor: '#FAFAFA', color: '#5A5555', border: '1px #CCCBCB solid' }} /> */}
            </div>
            <div className="d-flex space-x-5">
              <CommonButton title="Record Insemination" style={{ backgroundColor: 'none', color: '#16A34A', border: '1px solid #16A34A' }} onClick={handlebtnclick} />
              {/* <CommonButton title="Record Delivery" onClick={handleclick} /> */}
            </div>
          </div>
          <Cards data={carddata} cardClick={cardClick} />
          <Row className="gx-3 gy-4">
            <Col xl={4}>
              <Commonbarchart title="Insemination Report" img={Insemination_report} bgcolor={'#16A34A'} data={transformedData} type="insemination" bgcolor2={'#8ACE96'} bgcolor3={'#B7F5CE'} />
            </Col>
            <Col xl={8}>
              <BreedingList title="Pending Verifications" text="Delivery in 1 day" bgcolor={'#28E26D'} cattleData={pendingList} onCardClick={oncardclick} />
            </Col>
          </Row>
          <Row className="gx-3 gy-4">
            <Col xl={4}>
              <Commonbarchart title="Delivery Report" img={frame2} bgcolor={'#FDBF26'} bgcolor3={'#FFEEC3'} bgcolor2={'#FFD16C'} data={transformedDeliveryReportData} type='delivery' />
            </Col>
            <Col xl={8}>
              <BreedingList title="Upcoming Delivery" text="Delivery in 1 day" bgcolor={'#FDBF26'} cattleData={upcommingList} onCardClick={oncardclick} />
            </Col>
          </Row>
        </FlexCol>
      )}
      {/* <RecordDeliveryForm isOpen={Deliveryreport} toggle={handleclick} /> */}
      {insemination && <InseminationReport />}
    </div>
  );
}

export default Breeding;
