import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  schoolData: {},
};

const schoolDataSlice = createSlice({
  name: "schoolData",
  initialState,
  reducers: {
    schoolDetails(state, action) {
      state.schoolData = action.payload;
    },
  },
});

export const { schoolDetails } = schoolDataSlice.actions;

export default schoolDataSlice.reducer;
