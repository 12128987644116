import React, { useState } from 'react';
import { useFormik } from 'formik';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import TextArea from '../Common/TextArea';
import DateSelect from '../Common/DateSelector';
import { Flex, notification } from 'antd';
import FlexCol from '../Common/FlexCol';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import API, { action } from '../../Api';
import * as Yup from 'yup';
function RecordDelivery({ isOpen, toggle, data }) {
    console.log(data, "ooo");

    const tabs = ['Male', 'Female'];
    const [loading, setLoading] = useState(false); 
    const validationSchema = Yup.object().shape({
        deliveryDate: Yup.date().required('Delivery date is required'),
        calves: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string().required('Calf ID is required'),
                    weight: Yup.number()
                        .typeError('Weight must be a number')
                        .positive('Weight must be a positive number')
                        .required('Calf weight is required'),
                    gender: Yup.string().oneOf(['Male', 'Female'], 'Gender is required'),
                })
            )
            .required('At least one calf is required'),
        description: Yup.string().required('Description is required'),
    });
    const formik = useFormik({
        validationSchema,

        initialValues: {
            deliveryDate: '',
            calves: [{ id: '', weight: '', gender: 'Male' }],
            description: '',
            parentCattleId: '',  // Using a fallback value if data.CattleID is undefined
            id: '',

        },
        onSubmit: async (values) => {
            console.log(values);
            // Handle form submission here
            setLoading(true);
            try {
                // Perform the async action here
                const result = await action(API.ADD_DELIVERY, {

                    id: data?.[0]?.inseminationId,  // Ensure id is never undefined
                    parent_cattle_id: data?.[0]?.CattleID,
                    delivery_date: values.deliveryDate,
                    delivery_notes: values.description,
                    calves: values.calves.map((calf) => ({
                        calf_id: calf.id,
                        gender: calf.gender,
                        weight: calf.weight,
                    }))

                });
                notification.success({ message: result?.response?.message, placement: "top", });
                // Reset the form and close the offcanvas after submission
                formik.resetForm();
                customToggle();  // Assuming `toggle` is defined to close the offcanvas
            } catch (error) {
                notification.error({ message: error.response?.data?.response?.message, placement: "top", });
                // console.error(id ? 'Error updating role:' : 'Error creating role:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        }
    });

    const handleAddCalf = () => {
        formik.setFieldValue('calves', [
            ...formik.values.calves,
            { id: '', weight: '', gender: 'Male' },
        ]);
    };

    const handleInputChange = (index, field, value) => {
        const updatedCalves = [...formik.values.calves];
        updatedCalves[index][field] = value;
        formik.setFieldValue('calves', updatedCalves);
    };
    const customToggle = () => {
        formik.resetForm({
            values: {
                deliveryDate: '',
                calves: [{ id: '', weight: '', gender: 'Male' }],
                description: '',
                parentCattleId: '',
                id: '',
            },
        });
        toggle();
    };
    return (
        <Offcanvas
            isOpen={isOpen}
            toggle={customToggle}
            direction="end"
            className="!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
        >
            <FlexCol className={"p-4"}>
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Record Delivery
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Add delivery data and notes.
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <FormGroup>
                        <FlexCol className={""} gap={20}>
                            <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                <DateSelect
                                    title="Delivery Date"
                                    placeholder="04 Oct 2024"
                                    value={formik.values.deliveryDate}
                                    change={(e) => formik.setFieldValue('deliveryDate', e)}
                                    error={formik.touched.deliveryDate && formik.errors.deliveryDate} 
                                    required= {true}
                                    disabledFutureDate={true}
                                />
                                {formik.values.calves.map((calf, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            gap: 20,
                                            display: 'flex',
                                        }}
                                    >
                                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                            Calf Gender
                                        </div>
                                        <div className="flex gap-2">
                                            {tabs.map((tab) => (
                                                <div
                                                    key={tab}
                                                    onClick={() => handleInputChange(index, 'gender', tab)}
                                                    className={`p-2 rounded-lg cursor-pointer ${calf.gender === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'
                                                        } flex justify-center items-center`}
                                                >
                                                    <div
                                                        className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans"
                                                    >
                                                        {tab}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <FormInput
                                            title="New Calf Id"
                                            placeholder="1564"
                                            value={calf.id}
                                            change={(e) => handleInputChange(index, 'id', e)}
                                            error={formik.touched.calves?.[index]?.id && formik.errors.calves?.[index]?.id} 
                                            required= {true}
                                        />
                                       
                                        <FormInput
                                            title="Calf Weight"
                                            type='number'
                                            placeholder="35 Kg"
                                            value={calf.weight}
                                            change={(e) => handleInputChange(index, 'weight', e)}
                                            required= {true}
                                            error={formik.touched.calves?.[index]?.weight && formik.errors.calves?.[index]?.weight} 
                                        />
                                        
                                    </div>
                                ))}
                                <CommonButton
                                    title="Add Another Calf"
                                    style={{ fontSize: '10px' }}
                                    onClick={handleAddCalf}
                                />
                                <TextArea
                                    title="Description"
                                    placeholder="Add Description"
                                    value={formik.values.description}
                                    change={(e) => formik.setFieldValue('description', e)}
                                    error={formik.touched.description && formik.errors.description}
                                    required= {true}
                                />
                                
                            </Flex>
                        </FlexCol>
                    </FormGroup>
                    <div className="flex justify-between mt-8">
                        <CommonButton
                            style={{
                                fontSize: '12px',
                                backgroundColor: 'none',
                                color: '#726C6C',
                                border: '1px #CCCBCB solid ',
                                marginRight: '8px',
                            }}
                            onClick={customToggle}
                            outline
                            title="Discard"
                            disabled={loading}
                        />
                        <CommonButton
                            title="Save Delivery"
                            style={{ fontSize: '12px' }}
                            onClick={() => formik.handleSubmit()}
                            disabled={loading}
                        />
                    </div>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}

export default RecordDelivery;
