import React, { useEffect, useState } from 'react';
import API, { action } from '../../../Api';

function Vaccination(Selected) {
    const vaccinations = [
        {
            treatmentDate: "24 October 2024",
            cost: 250,
            recordedBy: "Dr. Pashupathi",
            remarks: "Completely Fit",
        },

        // Add more vaccination records as needed
    ];

    console.log(Selected,"selected user");
    

const [vaccinationList,setVaccinationList]=useState([])

    const getVaccineList = async () => {
        try {
            const result = await action(API.GET_CATTLE_VACCINATION_DATA, {
                cattle_id:Selected?.Selected?.cattle_id,
                page: 1,
                pageCount: 10
            });
            setVaccinationList(result.response.data);
        } catch (error) {
            console.error("Error fetching vaccination list:", error);
        }
    };
useEffect(() => {

    getVaccineList();  // Fetch cattle list if cattle_id is present
  
}, [Selected])

    return (
        <div className="basic-info-container">
            <div className="flex justify-between items-center">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Vaccination
                </p>
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center">
                <div>
                    <p><strong>Vaccination Date</strong></p>
                    <p><strong>Vaccination Name</strong></p>
                    <p><strong>Vaccination Type</strong></p>
                    <p><strong>Dosage</strong></p>
                    <p><strong>Health Status</strong></p>
                    <p><strong>Next Vaccination Date</strong></p>
                </div>
                {vaccinations.map((vaccination, index) => (
                    <div key={index} className="ml-10 text-[#0A0B0A]">
                        <p>{vaccination?.[0]?.vaccination_date || "--"}</p>
                        <p>{vaccination.vaccination_name || "--"}</p>
                        <p>{vaccination.vaccination_type || "--" }</p>
                        <p>{vaccination.dosage || "--"}</p>
                        <p>{vaccination.health_status || "--"}</p>
                        <p>{vaccination.next_vaccination_date || "--"}</p>
                    </div>
                ))}
            </div>
            {/* <div className="flex justify-between items-center mt-4">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Foot And Mouth Disease
                </p>
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center">
                <div>
                    <p><strong>Treatment Date</strong></p>
                    <p><strong>Cost In Rupees</strong></p>
                    <p><strong>Recorded By</strong></p>
                    <p><strong>Doctor Remarks</strong></p>
                    <p><strong>Health Status</strong></p>
                    <p><strong>Next Vaccination Date</strong></p>
                </div>
                {vaccinations.map((vaccination, index) => (
                    <div key={index} className="ml-10 text-[#0A0B0A]">
                        <p>{vaccination.treatmentDate}</p>
                        <p> ₹ {vaccination.cost}</p>
                        <p>{vaccination.recordedBy}</p>
                        <p className="italic font-normal">{vaccination.remarks}</p>
                    </div>
                ))}
            </div>
            <div className="flex justify-between items-center mt-4">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Vaccination
                </p>
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center">
                <div>
                    <p><strong>Treatment Date</strong></p>
                    <p><strong>Cost In Rupees</strong></p>
                    <p><strong>Recorded By</strong></p>
                    <p><strong>Doctor Remarks</strong></p>
                </div>
                {vaccinations.map((vaccination, index) => (
                    <div key={index} className="ml-10 text-[#0A0B0A]">
                        <p>{vaccination.treatmentDate}</p>
                        <p> ₹ {vaccination.cost}</p>
                        <p>{vaccination.recordedBy}</p>
                        <p className="italic font-normal">{vaccination.remarks}</p>
                    </div>
                ))}
            </div> */}
        </div>
    );
}

export default Vaccination;
