import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import Dropdownbtn from '../Common/Dropdownbtn'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import { Nav, NavItem, NavLink } from 'reactstrap'
import ReactTable from '../Common/Table'
import cowicon from "../../assets/Images/Cow icon.png"
import RecordVaccine from '../Drawer/RecordVaccine'
import API, { action } from '../../Api'
import TableAccordion from '../Common/TableAccordion'
import DateFilter from '../Common/DateFilter'
import RecordDeworming from '../Drawer/RecordDeworming'

function DewormingReport() {
    const [deworm, setDeworm] = useState(false)
    const [dewormList, setDewormList] = useState([])
    const [tab, setTab] = useState("1");
    const [selectedData, setSelectedData] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const [paginationcount, setPaginationcount] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [datefilter, setDatefilter] = useState()
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };

    const handleActionClick = (userId) => {setDeworm(!deworm)
        setSelectedData(userId)
        getDewormingReport()
    }

    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Medicine Name",
            value: "MedicineName",

        },


        {
            id: 4,
            title: "Last Dewormed",
            value: "deworm_date",


        },
        {
            id: 5,
            title: "Serverity",
            value: "serverity",


        },
        {
            id: 6,
            title: "Status",
            value: "status",


        },
        {
            id: 7,
            title: "Action",
            value: "action",


        },



    ]

    const headingsformobile = [

        
       
        {
            id: 1,
            title: "Medicine Name",
            value: "MedicineName",

        },


        {
            id: 2,
            title: "Last Dewormed",
            value: "deworm_date",


        },
        {
            id: 3,
            title: "Serverity",
            value: "serverity",


        },
        {
            id: 4,
            title: "Status",
            value: "status",


        },
       



    ]


    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastVaccinated: "06-09-2024",
            HealthStatus: "Normal",
            NextVaccination: "11-10-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastVaccinated: "02-08-2024",
            HealthStatus: "Normal",
            NextVaccination: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastVaccinated: "15-06-2024",
            HealthStatus: "Major",
            NextVaccination: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastVaccinated: "20-07-2024",
            HealthStatus: "Normal",
            NextVaccination: "05-10-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastVaccinated: "25-05-2024",
            HealthStatus: "Critical",
            NextVaccination: "30-09-2024",
            action: "View Report",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastVaccinated: "18-08-2024",
            HealthStatus: "Normal",
            NextVaccination: "20-10-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            LastVaccinated: "01-09-2024",
            HealthStatus: "Critical",
            NextVaccination: "15-11-2024",
            action: "View Report",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            LastVaccinated: "12-06-2024",
            HealthStatus: "Normal",
            NextVaccination: "25-10-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            LastVaccinated: "14-08-2024",
            HealthStatus: "Normal",
            NextVaccination: "02-11-2024",
            action: "View Report",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            LastVaccinated: "27-07-2024",
            HealthStatus: "Normal",
            NextVaccination: "16-10-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            LastVaccinated: "03-09-2024",
            HealthStatus: "Normal",
            NextVaccination: "28-11-2024",
            action: "View Report",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            LastVaccinated: "30-05-2024",
            HealthStatus: "Critical",
            NextVaccination: "14-10-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            LastVaccinated: "19-08-2024",
            HealthStatus: "Major",
            NextVaccination: "01-11-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            VaccinationName: "2",
            LastVaccinated: "10-07-2024",
            HealthStatus: "Major",
            NextVaccination: "15-10-2024",
            action: "Vaccinate",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            LactationNumber: "1",
            LastVaccinated: "22-09-2024",
            HealthStatus: "Critical",
            NextVaccination: "29-11-2024",
            action: "Vaccinate",
        },
    ];

    const getDewormingReport = async (page = 1, pageSize = 10) => {
        const result = await action(API.GET_DEWORMING, {

            searchkey: searchTerm,
            page: page,
            pageCount: pageSize,
            // date: datefilter,
        });
        console.log(result.response.data, "get milk list")
        // setEmployeeLeave(result.result);
        setPaginationcount(result.response.count);
        setDewormList(result.response.data)
        const formattedData = result.response.data.map((item, index) => ({
            SlNo: ((page - 1) * pageSize + index + 1).toString().padStart(2, '0'), // Format SlNo
            CattleID: item.Cattle.cattle_id
            , // Adjust according to your API response structure
            MedicineName: item.medicine_name,
            deworm_date: item.deworm_date,
            serverity: item.serverity,
            status: item.status,
            action: "Deworm",
            dosage : item.dosage,
            vaccination_type : item.vaccination_type,
            nextdewormingdate : item.next_deworm_date,
            id : item.id

        }))

        setDewormList(formattedData);
    };



    useEffect(() => {
        getDewormingReport()
        console.log(dewormList, 'dewormList');

    }, [pageSize, currentPage, searchTerm,datefilter])

    const filteredUserList = (dewormList || []).filter(user => {
        const CattleID = user.CattleID || ""; // Prevent null error

        return (
            CattleID.toLowerCase().includes(searchTerm.toLowerCase())

        )
    });

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ backgroundColor: "#FAFAFA"  }} className='h-[calc(100vh_-_135px)]'>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <div className='d-flex space-x-2'>
                        <Heading title='Deworming Report' />
                        {/* <Dropdownbtn title='Today' style={{ backgroundColor: "#FAFAFA", color: "#5A5555", border: '1px #CCCBCB solid' }} /> */}
                    </div>
                    <div className="d-flex space-x-5">
                    {/* <DateFilter selectedDate={datefilter} change={(e) => setDatefilter(e)} placeholder="Date Filter" value={datefilter} /> */}
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' value={searchTerm} onSearch={setSearchTerm} />
                        <Filterbtn />

                    </div>
                </div>
                {/* <Cards />
                <Row className="g-3">
                    <Col md={7} >
                        <Graph title='Sick Cattle Statistics' />

                    </Col>
                    <Col md={5} >
                        <PieChart title="Sick Cattle Distribution" />

                    </Col>

                </Row> */}
                {/* <Nav pills className="nav-success ">
                    {["All", "Vaccinated", "Pending", "Not in medication", "Critical", "Recovered"].map((tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav> */}
                {/* {tab === "1" && <ReactTable headings={headings} data={filteredUserList} headingcolor="#D9D9D9" handleAction={handleActionClick}  pagination={paginationcount} fetchData={getVaccinationReport} />}
                <TableAccordion/> */}

                {isMobile ? (
                    <TableAccordion headings={headingsformobile} cattleData={filteredUserList} />
                ) : (
                    <ReactTable
                        headings={headings}
                        data={filteredUserList}
                        headingcolor="#D9D9D9"
                        handleAction={handleActionClick}
                        pagination={paginationcount}
                        fetchData={getDewormingReport}
                    />
                )}
            </FlexCol>
            <RecordDeworming isOpen={deworm} toggle={handleActionClick} selectedData={selectedData} />
        </div>
    )
}


export default DewormingReport