import React, { useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import CommonButton from '../Common/Button'

import ReactTable from '../Common/Table'
import cowicon from "../../assets/Images/Cow icon.png"
import { Nav, NavItem, NavLink } from 'reactstrap'


function CattleReport() {
  const headings = [

    {
      id: 1,
      title: "Sl No",
      value: "SlNo",

    },
    {
      id: 2,
      title: "Cattle ID",
      value: "cattleId",
      icon: cowicon

    },
    {
      id: 3,
      title: "Lactation Number",
      value: "lactationNumber",

    },

    // {
    //   id: 4,
    //   title: t("Created_On"),
    //   value: "createdOn",
    //   dataIndex: "createdOn",
    //   sorter: (a, b) => {
    //     const dateA = new Date(a.createdOn);
    //     const dateB = new Date(b.createdOn);
    //     return dateA.getTime() - dateB.getTime();
    //   },
    //   sortOrder: "ascent",
    // },
    {
      id: 5,
      title: "Last Calving date",
      value: "lastCalvingDate",


    },
    {
      id: 5,
      title: "Heat Type",
      value: "heatType",


    },
    {
      id: 5,
      title: "Heat Date",
      value: "heatDate",


    },
    {
      id: 5,
      title: "Status",
      value: "status",

    },



  ]

  const data = [
    {
      SlNo: "1",
      cattleId: "Cattle No.1856",
      lactationNumber: "2",
      lastCalvingDate: "06-09-2024",
      heatType: "Natural",
      heatDate: "11-10-2024",
      status: "Inseminated"
    },
    {
      SlNo: "2",
      cattleId: "Georgie Winters",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Artificial",  // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Pregnant"
    },
    {
      SlNo: "3",
      cattleId: "Whitney Meier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Pregnant"
    },
    {
      SlNo: "4",
      cattleId: "Justin Maier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "06-09-2024",         // Placeholder if not available
      status: "Inseminated"
    },
    {
      SlNo: "1",
      cattleId: "Cattle No.1856",
      lactationNumber: "2",
      lastCalvingDate: "06-09-2024",
      heatType: "Natural",
      heatDate: "11-10-2024",
      status: "Pregnant"
    },
    {
      SlNo: "2",
      cattleId: "Georgie Winters",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Artificial",  // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Pregnant"
    },
    {
      SlNo: "3",
      cattleId: "Whitney Meier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Inseminated"
    },
    {
      SlNo: "4",
      cattleId: "Justin Maier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "06-09-2024",         // Placeholder if not available
      status: "Dry Cattle"
    },
    {
      SlNo: "1",
      cattleId: "Cattle No.1856",
      lactationNumber: "2",
      lastCalvingDate: "06-09-2024",
      heatType: "Natural",
      heatDate: "11-10-2024",
      status: "Inseminated"
    },
    {
      SlNo: "2",
      cattleId: "Georgie Winters",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Artificial",  // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Dry Cattle"
    },
    {
      SlNo: "3",
      cattleId: "Whitney Meier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Milking"
    },
    {
      SlNo: "4",
      cattleId: "Justin Maier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "06-09-2024",         // Placeholder if not available
      status: "Inseminated"
    },
    {
      SlNo: "1",
      cattleId: "Cattle No.1856",
      lactationNumber: "2",
      lastCalvingDate: "06-09-2024",
      heatType: "Natural",
      heatDate: "11-10-2024",
      status: "Inseminated"
    },
    {
      SlNo: "2",
      cattleId: "Georgie Winters",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Artificial",  // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Milking"
    },
    {
      SlNo: "3",
      cattleId: "Whitney Meier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Milking"
    },
    {
      SlNo: "4",
      cattleId: "Justin Maier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "06-09-2024",         // Placeholder if not available
      status: "Milking"
    },
    {
      SlNo: "1",
      cattleId: "Cattle No.1856",
      lactationNumber: "2",
      lastCalvingDate: "06-09-2024",
      heatType: "Natural",
      heatDate: "11-10-2024",
      status: "Inseminated"
    },
    {
      SlNo: "2",
      cattleId: "Georgie Winters",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Artificial",  // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Pregnant"
    },
    {
      SlNo: "3",
      cattleId: "Whitney Meier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "11-10-2024",         // Placeholder if not available
      status: "Milking"
    },
    {
      SlNo: "4",
      cattleId: "Justin Maier",
      lactationNumber: "1",  // Added a value for Lactation Number
      lastCalvingDate: "06-09-2024", // Placeholder if not available
      heatType: "Natural",     // Added a value for Heat Type
      heatDate: "06-09-2024",         // Placeholder if not available
      status: "Inseminated"
    },

  ];
  const Inseminated_Data = [{
    SlNo: "1",
    cattleId: "Cattle No.1856",
    lactationNumber: "2",
    lastCalvingDate: "06-09-2024",
    heatType: "Natural",
    heatDate: "11-10-2024",
    status: "Inseminated"
  },
  {
    SlNo: "2",
    cattleId: "Georgie Winters",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Artificial",  // Added a value for Heat Type
    heatDate: "11-10-2024",         // Placeholder if not available
    status: "Pregnant"
  },
  ]
  const HeatedCattle_Data = [{
    SlNo: "1",
    cattleId: "Cattle No.1856",
    lactationNumber: "2",
    lastCalvingDate: "06-09-2024",
    heatType: "Natural",
    heatDate: "11-10-2024",
    status: "Inseminated"
  },
  {
    SlNo: "2",
    cattleId: "Georgie Winters",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Artificial",  // Added a value for Heat Type
    heatDate: "11-10-2024",         // Placeholder if not available
    status: "Pregnant"
  },
  {
    SlNo: "4",
    cattleId: "Justin Maier",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Natural",     // Added a value for Heat Type
    heatDate: "06-09-2024",         // Placeholder if not available
    status: "Inseminated"
  },
  ]
  const Pregnant_Data = [{
    SlNo: "1",
    cattleId: "Cattle No.1856",
    lactationNumber: "2",
    lastCalvingDate: "06-09-2024",
    heatType: "Natural",
    heatDate: "11-10-2024",
    status: "Inseminated"
  },
  {
    SlNo: "2",
    cattleId: "Georgie Winters",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Artificial",  // Added a value for Heat Type
    heatDate: "11-10-2024",         // Placeholder if not available
    status: "Pregnant"
  },
  {
    SlNo: "4",
    cattleId: "Justin Maier",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Natural",     // Added a value for Heat Type
    heatDate: "06-09-2024",         // Placeholder if not available
    status: "Inseminated"
  },
  ]
  const Milking_Data = [{
    SlNo: "1",
    cattleId: "Cattle No.1856",
    lactationNumber: "2",
    lastCalvingDate: "06-09-2024",
    heatType: "Natural",
    heatDate: "11-10-2024",
    status: "Inseminated"
  },
  {
    SlNo: "2",
    cattleId: "Georgie Winters",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Artificial",  // Added a value for Heat Type
    heatDate: "11-10-2024",         // Placeholder if not available
    status: "Pregnant"
  },
  {
    SlNo: "4",
    cattleId: "Justin Maier",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Natural",     // Added a value for Heat Type
    heatDate: "06-09-2024",         // Placeholder if not available
    status: "Inseminated"
  },
  {
    SlNo: "2",
    cattleId: "Georgie Winters",
    lactationNumber: "1",  // Added a value for Lactation Number
    lastCalvingDate: "06-09-2024", // Placeholder if not available
    heatType: "Artificial",  // Added a value for Heat Type
    heatDate: "11-10-2024",         // Placeholder if not available
    Status: "Pregnant"
  },
  ]

  const [pillsTab, setpillsTab] = useState("1");
  const pillsToggle = (tab) => {
    if (pillsTab !== tab) {
      setpillsTab(tab);
    }
  };
  return (
    <div style={{ backgroundColor: "#FAFAFA" ,minHeight: "calc(100vh - 135px)" }}>
      <FlexCol>
        <div className="d-flex justify-content-between ">
          <Heading title='Cattle Report' description='Detailed Report of Cattle' />
          <div className="d-flex space-x-5 ">
            <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' />
            <Filterbtn />
            <CommonButton title='Add Cattle' />
          </div>

        </div>
        <Nav pills className="nav-success ">
          {["All", "Inseminated", "Heated Cattle", "Pregnant", "Milking", "Dry"].map((tab, index) => (
            <NavItem key={index}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: "#0A0B0A",
                  backgroundColor: pillsTab === String(index + 1) ? '#E3E3E3' : 'transparent',
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "120%",
                  textDecoration: 'none' // Optional underline for active tab
                }}
                className={pillsTab === String(index + 1) ? "active" : ""}
                onClick={() => pillsToggle(String(index + 1))}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        {pillsTab === "1" && <ReactTable headings={headings} data={data} />}
        {pillsTab === "2" && <ReactTable headings={headings} data={Inseminated_Data} />}
        {pillsTab === "3" && <ReactTable headings={headings} data={HeatedCattle_Data} />}
        {pillsTab === "4" && <ReactTable headings={headings} data={Pregnant_Data} />}
        {pillsTab === "5" && <ReactTable headings={headings} data={ Milking_Data} />}
      </FlexCol>

    </div>
  )
}

export default CattleReport