import React from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import CommonButton from '../Common/Button'
import Cards from '../Common/Cards'
import MyTask from '../Common/MyTask'
import { Col, Row } from 'reactstrap'
import ActivityLogCard from '../Common/ActivityLogCard'
import {  useNavigate } from 'react-router'

function ActivityLog() {
    const navigate = useNavigate();
    const handlemedicationClick =()=>{
        navigate('/healthMonitoring/medicationReport')
      }
  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
    <FlexCol>
        <div className="d-flex justify-content-between ">
            <Heading title='Activity Log' />
            <div className="d-flex space-x-5">
                
                {/* <CommonButton title="Add Record" /> */}
            </div>
        </div>
        {/* <Cards/> */}
        <ActivityLogCard/>
        <Row className="g-3">
    <Col md={6} >
        <MyTask heading="Treatments Checklist" btntitle='Add Treatment' onclick={handlemedicationClick} />
        
    </Col>
    <Col  md={6} >
        <MyTask heading="Feeding Checklist" btntitle='Add New' />
    </Col>
</Row>
        </FlexCol>
        </div>
  )
}

export default ActivityLog