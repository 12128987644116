import { Input } from "antd";
import React from "react";
import { LuSearch } from "react-icons/lu";

export default function SearchBox({
    placeholder = "",
    value = "",
    icon = <LuSearch />,
    className = "",
    onSearch = () => {},
}) {
    return (
        <div className="relative">
            <Input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => onSearch(e.target.value)}
                // className={`w-full border focus:outline-none ${className}`}
                prefix={icon}
            />
        </div>
    );
}
