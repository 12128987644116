import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import StockInventoryChart from '../Dashboard/AdminDashbord/Stockinventorygraph/StockInventoryChart';
import ReportChart from '../Reports Monitoring/ReportChart';

function ReportMonitoringComparisonGraph({ title, CategoryName, CategoryName2 }) {
    const [chartData, setChartData] = useState([]);
    const [neededData, setNeededData] = useState([]);
    const [availabeData, setAvailableData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);
    const [chartcolor, setChartColor] = useState([]);

    const getRevenueChartsData = async (period) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const availabeData = [
            { name: "Jan", value: 900 },
            { name: "Feb", value: 900 },
            { name: "Mar", value: 700 },
            { name: "Apr", value: 800 },
            { name: "May", value: 700 },
            { name: "Jun", value: 800 },
            { name: "Jul", value: 900 },
            { name: "Aug", value: 600 },
            { name: "Sep", value: 900 },
        ];

        switch (period) {
            case "all":
                return {
                    current: [
                        { name: "Q1", value: 700 },
                        { name: "Q2", value: 500 },
                        { name: "Q3", value: 400 },
                        { name: "Q4", value: 600 },
                        { name: "Q5", value: 300 },
                        { name: "Q6", value: 500 },
                        { name: "Q7", value: 600 },
                        { name: "Q8", value: 500 },
                        { name: "Q9", value: 600 },
                    ],
                    previous: availabeData,
                };
            case "month":
                return {
                    current: [
                        { name: "Week 1", value: 2000 },
                        { name: "Week 2", value: 3000 },
                        { name: "Week 3", value: 2500 },
                        { name: "Week 4", value: 3500 },
                    ],
                    previous: [
                        { name: "Week 1", value: 1800 },
                        { name: "Week 2", value: 2700 },
                        { name: "Week 3", value: 2300 },
                        { name: "Week 4", value: 3200 },
                    ],
                };
            // Add similar blocks for "halfyear" and "year"
            default:
                return { current: [], previous: [] };
        }
    };

    const fetchRevenueData = async (period) => {
        const { current, previous } = await getRevenueChartsData(period);
        setAvailableData(previous); // Update previous data
        setNeededData(current); // Update current revenue data
    };

    useEffect(() => {
        fetchRevenueData("all"); // Fetch initial data

        // Set the chart color based on the title
        if (CategoryName === "Recovered") {
            setChartColor(['#E4626F', '#FFA7B0']); 
        } 
        else if (CategoryName === "Milked") {
            setChartColor(['#0EA5E9', '#ACE2FA']); 
        }
        else if (CategoryName === "Pregnant") {
            setChartColor(['#FDBF26', '#FEEAB7']);
        } else if (CategoryName === "Stock") {
            setChartColor(['#16A34A', '#DBFAE7']);
        }   
        else {
            setChartColor(['#1f77b4', '#ff7f0e']); // Default colors if title is not "abc"
        }
    }, [CategoryName]);

    useEffect(() => {
        if (neededData.length > 0 || availabeData.length > 0) {
            const formattedData = [
                {
                    name: CategoryName,
                    type: 'bar',
                    data: neededData.map(item => item.value),
                },
                {
                    name: CategoryName2,
                    type: 'bar',
                    data: availabeData.map(item => item.value),
                },
            ];

            const categories = [...new Set([...availabeData, ...neededData].map(item => item.name))]; // Unique categories

            setChartData(formattedData);
            setChartCategories(categories);
        }
    }, [neededData, availabeData, CategoryName, CategoryName2]);

    const onChangeChartPeriod = (pType) => {
        fetchRevenueData(pType);
    };

    return (
        <React.Fragment>
            <div className="2xl:p-6 p-2  bg-white rounded-xl border border-gray-300">
                <CardBody className="p-0 pb-2">
                    <div className="w-100">
                        <div className="border-0 align-items-center d-flex ml-5">
                            <h4 className="card-title mb-0 flex-grow-1 mt-2" style={{
                                color: "#495057",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}>{title}</h4>
                        </div>
                        <div dir="ltr">
                            <ReportChart
                                series={chartData}
                                dataColors='["--vz-secondary", "--vz-primary", "--vz-primary-rgb, 0.50"]'
                                categories={chartCategories}
                                barColors={chartcolor} // Use the chart color state here
                            />
                        </div>
                    </div>
                </CardBody>
            </div>
        </React.Fragment>
    );
}

export default ReportMonitoringComparisonGraph;
