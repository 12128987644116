import { Checkbox, Drawer, Flex, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CommonButton from '../Common/Button';
import FormInput from '../Common/FormInput';
import FlexCol from '../Common/FlexCol';
import API, { action } from '../../Api';

const CreateRole = ({ isOpen, toggle, selectedUserId }) => {
    const [rolePermission, setRolePermission] = useState([]);
    const [id, setId] = useState(selectedUserId);
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        setId(selectedUserId);
    }, [selectedUserId]);

    const getRolePermission = async () => {
        try {
            const result = await action(API.GET_ROLE_PERMISSION);
            const permissions = result.response.data.map(module => ({
                label: module.module_name,
                value: module.id
            }));
            setRolePermission(permissions);
        } catch (error) {
            console.error("Error fetching module data:", error);
        }
    };

    useEffect(() => {
        getRolePermission();
    }, []);

    useEffect(() => {
        if (selectedUserId && selectedUserId.length > 0) {
            formik.setFieldValue("roleName", selectedUserId[0]?.role);
            formik.setFieldValue("permissions", selectedUserId[0]?.permission || "");
        }
    }, [selectedUserId]);

    const formik = useFormik({
        initialValues: {
            roleName: "",
            permissions: [],
        },
        validationSchema: Yup.object({
            roleName: Yup.string()
                .matches(
                    /^[a-zA-Z0-9\s]*$/,
                    'Role name should only contain letters, numbers, and spaces'
                )
                .required('Role name is required'),
            permissions: Yup.array().min(1, 'At least one permission is required'),
        }),
        onSubmit: async(values) => {
            setLoading(true);
            try {
                if (id) {
                    await action(API.UPDATE_ROLE, {
                        id: selectedUserId[0].id,
                        role: values.roleName,
                        privileges: values.permissions,
                    });
                    notification.success({
                        message: "User role updated successfully!",
                        placement: "top",
                    });
                } else {
                    await action(API.ADD_ROLE_AND_PERMISSION, {
                        role: values.roleName,
                        privileges: values.permissions,
                    });
                    notification.success({
                        message: "User role created successfully!",
                        placement: "top",
                    });
                }
                formik.resetForm();
                toggle();
            } catch (error) {
                console.error(id ? 'Error updating role:' : 'Error creating role:', error);
                notification.error({
                    message: error.response?.data?.response?.message,
                    placement: "top",
                });
            }
            finally {
                setLoading(false); // Stop loading
            }
        },
    });


    // Function to handle select all
    const handleSelectAll = (checked) => {
        const allPermissionValues = rolePermission.map(permission => permission.value);
        formik.setFieldValue("permissions", checked ? allPermissionValues : []);
    };

    // Function to check if all permissions are selected
    const areAllPermissionsSelected = () => {
        return rolePermission.length > 0 && 
               formik.values.permissions.length === rolePermission.length;
    };

    const handleCheckboxChange = (value) => {
        const updatedPermissions = formik.values.permissions?.includes(value)
            ? formik.values.permissions.filter((perm) => perm !== value)
            : [...(formik.values.permissions || []), value];
        formik.setFieldValue("permissions", updatedPermissions);
    };

    const customToggle = () => {
        setId(null);
        formik.resetForm();
        toggle();
    };

    return (
        <Offcanvas isOpen={isOpen} toggle={customToggle} direction="end" className="!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className="p-4">
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">{id ? "Role Updation" : "Role Creation"}</div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">{id ? "Update User Role" : "Create New User Role"}</div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FlexCol gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <FormInput
                                        title="Role Name"
                                        placeholder="Supervisor"
                                        value={formik.values.roleName}
                                        change={(e) => formik.setFieldValue("roleName", e)}
                                        error={formik.touched.roleName && formik.errors.roleName}
                                        required={true}
                                    />

                                    <div className="text-green-600 text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                        Permissions
                                    </div>

                                    {/* Select All Checkbox */}
                                    <div className="d-flex justify-content-between mb-2 text-[#5A5555] text-[14px] md:text-[12px] font-[600] leading-[16.8px] break-words font-plus-jakarta-sans">
                                        <div className="d-flex align-items-center">
                                            <Checkbox
                                                className="mr-2"
                                                checked={areAllPermissionsSelected()}
                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                            />
                                            <span className="text-gray-700 font-plus-jakarta-sans ">Select All</span>
                                        </div>
                                    </div>
                                    
                                    {rolePermission.map((permission) => (
                                        <div
                                            className="d-flex justify-content-between mb-2 text-[#5A5555] text-[14px] md:text-[12px] font-[600] leading-[16.8px] break-words font-plus-jakarta-sans"
                                            key={permission.value}
                                        >
                                            <div className="d-flex align-items-center">
                                                <Checkbox
                                                    className="mr-2"
                                                    checked={formik.values.permissions.includes(permission.value)}
                                                    onChange={() => handleCheckboxChange(permission.value)}
                                                />
                                                <span className="text-gray-700 font-plus-jakarta-sans">{permission.label}</span>
                                            </div>
                                        </div>
                                    ))}
                                    {formik.errors.permissions && formik.touched.permissions && (
                                        <div className="text-red-500 text-xs mt-1">{formik.errors.permissions}</div>
                                    )}
                                </Flex>
                            </FlexCol>
                        </FormGroup>

                        <div className="flex justify-between mt-8">
                            <CommonButton
                                style={{ backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid", marginRight: "8px", fontSize: "12px" }}
                                onClick={customToggle}
                                outline
                                title="Discard"
                                disabled={loading}
                            />

                            <CommonButton
                                title= {id ? "Update Role" : "Add Role"}
                                style={{ fontSize: "12px" }}
                                type="submit"
                                disabled={loading}
                            />
                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
};

export default CreateRole;