import React from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import CommonButton from '../Common/Button'
import ReportMonitoringComparisonGraph from '../Common/ReportMonitoringComparisonGraph'
import Cards from '../Common/Cards'

function CattleFeedingReport() {

    const data = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Cattle",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+16.24",
            counter: "100",
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Avg Feed Weight",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            percentage: "-3.57",
            counter: "72",
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Groups",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+29.08",
            counter: "52",
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Individual",
            badgeClass: "muted",
            percentage: "+0.00",
            counter: "81",
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },]
    return (
        <div style={{ backgroundColor: "#FAFAFA" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Cattle Feeding Report' />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                        <div className="flex space-x-5">
                            <SearchBox placeholder="Search Cattle" className="bg-[#F8F9FB]" />
                            <Filterbtn />
                        </div>
                        <div className="flex space-x-5 sm:mt-4">

                            <CommonButton title="Export" />
                        </div>
                    </div>
                </div>
                <ReportMonitoringComparisonGraph title="abc" CategoryName="Stock" CategoryName2="Needed" />
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Feeding Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#16A34A] 2xl:text-[32px]" />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">

                   

                            <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#16A34A", border: "1px #16A34A solid", }} />
                       
                    </div>
                </div>
                <Cards data={data} />
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Stock Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#0EA5E9] 2xl:text-[32px]" />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">

                    

                            <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#0EA5E9", border: "1px #0EA5E9 solid", }} />
                     
                    </div>
                </div>
                <Cards data={data} />
            </FlexCol>
        </div>
    )
}

export default CattleFeedingReport