import React, { useEffect, useState } from 'react'
import CommonButton from '../../Common/Button';
import API, { action } from '../../../Api';

function DietPlan(Selected) {

    const [treatmentList,setTreatmentList]=useState([])

    const getTreatmentList = async () => {
        try {
            const result = await action(API.GET_CATTLE_MEDICATION_DATA, {
                cattle_id:Selected?.Selected?.cattle_id,
                page: 1,
                pageCount: 10
            });
            setTreatmentList(result.response.data);
        } catch (error) {
            console.error("Error fetching treatment list:", error);
        }
    };
useEffect(() => {

    getTreatmentList();  // Fetch cattle list if cattle_id is present
  
}, [Selected])





  return (
    <div className="basic-info-container">
    <div className="flex justify-between items-center">
        <p className="text-[#16A34A] font-plus-jakarta-sans text-sm  font-semibold leading-6">
        Diet Plan 
        </p>
       
    </div>
    <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center ">
        <div>
            <p><strong>Issue Type</strong></p>
            <p><strong>Issue Date</strong></p>
            <p><strong>Severity</strong></p>
            <p><strong>Status</strong></p>
            <p><strong>Need Medicine</strong></p>
            
        </div>
        <div className="ml-10 text-[#0A0B0A]">
        <p>{treatmentList.issue_type || "--"}</p>
            <p>{treatmentList.issue_date || "--"}</p>
            <p>{treatmentList.severity || "--"}</p>
            <p>{treatmentList.status || "--"}</p>
            <p>{treatmentList.need_medicine || "--"}</p>
            
            
        </div>
    </div>
    
</div>
);
}

export default DietPlan