import React, { useEffect, useState } from 'react';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import { Col, FormGroup, Modal, ModalBody } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import API, { action } from '../../Api';
import DateSelect from '../Common/DateSelector';
import { notification } from 'antd'; // Import Ant Design notification
import DropdownButton from '../Common/DropdownButton';


function Basicinformation({ isOpen, toggle ,details}) {
 
    const [cattleType, setCattleType] = useState([]);
    const [maleData,setMaleData]= useState([])
    const [femaleData,setFemaleData] = useState([])
    const [loading, setLoading] = useState(false); 
console.log(cattleType,"details");
console.log(details,"nbb")

const gender = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];

    const formik = useFormik({
        initialValues: {
            id: details.id,
            cattleType: "",
            dob: "",
            gender: "",
            father: "",
            mother: "",
            cooperationNumber: "",
            licenceInformation: "",
        },
        validationSchema: Yup.object({
            cattleType: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const result = await action(API.UPDATE_CATTLE, {
                    id: details.id,
                    cattle_type: values.cattleType,
                    dob: values.dob,
                    gender: values.gender,
                    father: values.father,
                    mother: values.mother,
                    licence_info: values.licenceInformation,
                    corporation_no: values.cooperationNumber,
                });

                // formik.resetForm();
                toggle();

                if (result.status.code === 200) {
                    notification.success({
                        message: "Successfully updated cattle",
                        placement: "top",
                    });
                } else {
                    notification.error({
                        message: "Failed to update cattle",
                        placement: "top",
                    });
                }
            } catch (error) {
                console.error('Error updating cattle:', error);
                notification.error({
                    message: error.response?.data?.response?.message,
                    placement: "top",
                });
            } finally {
                setLoading(false); // Stop loading
            }
        },
    });

    

    useEffect(() => {
        if (details) {
            const selectedCattleType = cattleType.find(
                (type) => type.label === details.CattleType?.cattle_type_name
            )?.value || '';
            formik.setFieldValue("cattleType", selectedCattleType || "");
            formik.setFieldValue("dob", details.dob || "");
            formik.setFieldValue("gender", details.gender || "");
            formik.setFieldValue("father", details.father || "");
            formik.setFieldValue("mother", details.mother || "");
            formik.setFieldValue("licenceInformation", details.licence_info || "");
            formik.setFieldValue("cooperationNumber", details.corporation_no || "");
            formik.setFieldValue("cattle_id", details.cattle_id || "");
        }
    }, [details]);

    const getcattleType = async () => {
        try {
            const result = await action(API.GET_CATTLETYPE);
            const roles = result.response.data.map(role => ({
                label: role.cattle_type_name,
                value: role.id,
            }));
            setCattleType(roles);
        } catch (error) {
            console.error("Error fetching cattletype data:", error);
        }
    };
  
    useEffect(() => {
        getcattleType();
    }, [] );

    const getMalecattleType = async () => {
        try {
            const result = await action(API.GET_CATTLE_FILTER_GENDER,{
                type : 1
            });
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id, // Display name
                value: id.id // Unique identifier
            }));
            setMaleData(cattleID);
        } catch (error) {
            console.error("Error fetching cattletype data:", error);
        }
    };


    const getFemalecattleType = async () => {
        try {
            const result = await action(API.GET_CATTLE_FILTER_GENDER,{
                type : 2
            });
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id, // Display name
                value: id.id // Unique identifier
            }));
         
            setFemaleData(cattleID);
           
        } catch (error) {
            console.error("Error fetching cattletype data:", error);
        }
    };
    
    useEffect(() => {
        getFemalecattleType()
        getMalecattleType()
    }, [] );

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <div className='p-[40px]'>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">Basic Information</div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">Basic Information</div>
                    </div>
                </div>

                <ModalBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup className="px-[32px]">
                            <FlexCol gap={32}>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center gap-y-[40px]">
                                    <FormInput 
                                        title="Cattle ID" 
                                        placeholder="Enter Cattle ID" 
                                        value={formik.values.cattle_id} 
                                        change={(e) => formik.setFieldValue("cattle_id", e)} 
                                        disabled={true}
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                        Cattle Type
                                        <div className="flex gap-3 mt-2">
                                            {cattleType.map((type) => (
                                                <div
                                                    key={type.value}
                                                    onClick={() => formik.setFieldValue('cattleType', type.value)}
                                                   
                                                    
                                                    className={`p-2 rounded-lg cursor-pointer ${formik.values.cattleType === type.value ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                >
                                                    <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans">
                                                        {type.label}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <DateSelect 
                                        title="Date of Birth" 
                                        placeholder="Enter Date of Birth" 
                                        value={formik.values.dob} 
                                        change={(e) => formik.setFieldValue("dob", e)} 
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                    <DropdownButton 
                                        title="Gender" 
                                        placeholder="Gender" 
                                        options={gender}
                                        value={formik.values.gender} 
                                        change={(e) => formik.setFieldValue("gender", e)} 
                                    />
                                    <DropdownButton 
                                        title="Fathered By" 
                                        placeholder="Father" 
                                        value={formik.values.father}
                                        options={maleData} 
                                        change={(e) => formik.setFieldValue("father", e)} 
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                    <DropdownButton 
                                        title="Mothered By" 
                                        placeholder="Mother" 
                                        value={formik.values.mother} 
                                        options={femaleData}
                                        change={(e) => formik.setFieldValue("mother", e)} 
                                    />
                                    <FormInput 
                                        title="License Information" 
                                        placeholder="License Information" 
                                        value={formik.values.licenceInformation} 
                                        change={(e) => formik.setFieldValue("licenceInformation", e)} 
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                    <FormInput 
                                        title="Cooperation Number" 
                                        placeholder="Cooperation Number" 
                                        value={formik.values.cooperationNumber} 
                                        change={(e) => formik.setFieldValue("cooperationNumber", e)} 
                                    />
                                </div>

                                <Col className="flex justify-end mt-5 gap-2">
                                    <CommonButton title="Save" type="submit"  disabled={loading} />
                                    <CommonButton title="Cancel" type="button" onClick={toggle}  disabled={loading} />
                                </Col>
                            </FlexCol>
                        </FormGroup>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Basicinformation;
