import React, { useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import CommonButton from '../Common/Button'
import ReportMonitoringComparisonGraph from '../Common/ReportMonitoringComparisonGraph'
import Cards from '../Common/Cards'
import InseminationReport from '../Breeding/InseminationReport'
import DeliveryReport from '../Breeding/DeliveryReport'

function CattleBreedingReport() {

    const data = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Cattle",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+16.24",
            counter: "100",
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Avg Feed Weight",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            percentage: "-3.57",
            counter: "72",
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Groups",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+29.08",
            counter: "52",
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Individual",
            badgeClass: "muted",
            percentage: "+0.00",
            counter: "81",
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },]
const [inseminationReport,setInseminationReport] = useState(false)
const [deliveryReport,setDeliveryReport]= useState(false)
const handleInseminationReport = ()=> {
    setInseminationReport(true)
}
const handleDilveryReport =()=>{
    setDeliveryReport(true)
}
  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
          {(!inseminationReport && !deliveryReport) && (
    <FlexCol>
        <div className="flex flex-col sm:flex-row justify-between">
            <Heading title='Cattle Breeding Report' />
            <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                <div className="flex space-x-5">
                    <SearchBox placeholder="Search Cattle" className="bg-[#F8F9FB]" />
                    <Filterbtn />
                </div>
                <div className="flex space-x-5 sm:mt-4">
                   
                    <CommonButton title="Export"  />
                </div>
            </div>
        </div>
        <ReportMonitoringComparisonGraph title="abc"   CategoryName="Pregnant" CategoryName2="Inseminated"/>
        <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Insemination Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#6728F3] 2xl:text-[32px]" />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">

                      

                            <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#6728F3", border: "1px #6728F3 solid", }} onClick={handleInseminationReport} />
                    
                    </div>
                </div>
                <Cards data={data} />
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Delivery Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#FDBF26] 2xl:text-[32px]" />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">

                      

                            <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#FDBF26", border: "1px #FDBF26 solid", }} onClick={handleDilveryReport} />
                       
                    </div>
                </div>
                <Cards data={data} />
        </FlexCol>
          )}
        {inseminationReport && <InseminationReport/>}
        {deliveryReport && <DeliveryReport/>}
        </div>
  )
}

export default CattleBreedingReport