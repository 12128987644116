import React, { useEffect, useState } from 'react';
import CommonButton from '../../Common/Button';
import Basicinformation from '../../Drawer/Basicinformatiom';
import API, { action } from '../../../Api';

function BasicInformation({ Selected ,getCattleList }) {
    const [datails, setDetails] = useState(false)
    const [cattleDetails,setCattleDetails] = useState([])

    const handleclick = () => {setDetails(!datails)
        getCattleList()
        getcattleDetails()
    }
    console.log(Selected.id, "details");
    
    const getcattleDetails = async () => {
        try {
            const result = await action(API.GET_CATTLE_DETAILS,{
           id:Selected.id
            });
            
            setCattleDetails(result.response.data);
        } catch (error) {
            console.error("Error fetching cattletype data:", error);
        }
    };
  
    useEffect(() => {
        getcattleDetails();
    }, [Selected] );
    return (
        <div className="basic-info-container">
            <div className="flex justify-between items-center">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm  font-semibold leading-6">
                    Enrollment
                </p>
                <CommonButton title='Edit' style={{ fontSize: '12px', borderRadius: 10, backgroundColor: "#E3E3E3", color: "#2B2928", border: "none" }} onClick={handleclick} />
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center ">
                <div>
                    <p><strong>Cattle ID</strong></p>
                    <p><strong>Cattle Type</strong></p>
                    <p><strong>Date of Birth</strong></p>
                    <p><strong>Gender</strong></p>
                </div>
                <div className="ml-10 text-[#0A0B0A] capitalize">
                    <p>{cattleDetails.cattle_id ? cattleDetails.cattle_id : '--'}</p>
                    <p>{cattleDetails.CattleType?.cattle_type_name ? cattleDetails.CattleType?.cattle_type_name : '--'}</p>
                    <p>{cattleDetails.dob ? cattleDetails.dob : '--'}</p>
                    <p>{cattleDetails.gender ? cattleDetails.gender : '--'}</p>
                </div>
            </div>
            <div className="flex justify-between items-center mt-8">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Family Breed and Genetics
                </p>
                {/* <CommonButton title='Edit' style={{ fontSize: '12px', borderRadius: 10, backgroundColor: "#E3E3E3", color: "#2B2928", border: "none" }} /> */}
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center ">
                <div>
                    <p><strong>Fathered By</strong></p>
                    <p><strong>Mothered By</strong></p>
                </div>
                <div className="ml-10 text-[#0A0B0A] capitalize">
                    <p>{cattleDetails.Father?.cattle_id ? cattleDetails.Father?.cattle_id : '--'}</p>
                    <p>{cattleDetails.Mother?.cattle_id ? cattleDetails.Mother?.cattle_id : '--'}</p>
                </div>
            </div>
            <div className="flex justify-between items-center mt-8">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Additional Details
                </p>
                {/* <CommonButton title='Edit' style={{ fontSize: '12px', borderRadius: 10, backgroundColor: "#E3E3E3", color: "#2B2928", border: "none" }} /> */}
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center capitalize">
                <div>
                    <p><strong>License Information</strong></p>
                    <p><strong>Cooperation Number</strong></p>
                </div>
                <div className="ml-10 text-[#0A0B0A] capitalize">
                    <p>{cattleDetails.licence_info ? cattleDetails.licence_info : '--'}</p>
                    <p>{cattleDetails.corporation_no ? cattleDetails.corporation_no : '--'}</p>
                </div>
            </div>
            <Basicinformation isOpen={datails} toggle={handleclick} details={Selected} />
        </div>
    );
}

export default BasicInformation;
