import React, { useState, useEffect } from "react";
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import StockInventoryChart from "./StockInventoryChart";

function StockInventoryGraph({ title = "" }) {
    const [chartData, setChartData] = useState([]);
    const [neededData, setNeededData] = useState([]);
    const [availabeData, setAvailableData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);

    const getRevenueChartsData = async (period) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const availabeData = [
            { name: "Q1", value: 900 },
            { name: "Q2", value: 900 },
            { name: "Q3", value: 700 },
            { name: "Q4", value: 800 },
            { name: "Q5", value: 700 },
            { name: "Q6", value: 800 },
            { name: "Q7", value: 900 },

        ];

        switch (period) {
            case "all":
                return {
                    current: [
                        { name: "Q1", value: 700 },
                        { name: "Q2", value: 500 },
                        { name: "Q3", value: 400 },
                        { name: "Q4", value: 600 },
                        { name: "Q5", value: 300 },
                        { name: "Q6", value: 500 },
                        { name: "Q7", value: 600 },
                    ],
                    previous: availabeData,
                };
            case "month":
                return {
                    current: [
                        { name: "Week 1", value: 2000 },
                        { name: "Week 2", value: 3000 },
                        { name: "Week 3", value: 2500 },
                        { name: "Week 4", value: 3500 },
                    ],
                    previous: [
                        { name: "Week 1", value: 1800 },
                        { name: "Week 2", value: 2700 },
                        { name: "Week 3", value: 2300 },
                        { name: "Week 4", value: 3200 },
                    ],
                };
            // Add similar blocks for "halfyear" and "year"
            default:
                return { current: [], previous: [] };
        }
    };

    const fetchRevenueData = async (period) => {
        const { current, previous } = await getRevenueChartsData(period);
        setAvailableData(previous); // Update previous data
        setNeededData(current); // Update current revenue data
    };

    useEffect(() => {
        fetchRevenueData("all"); // Fetch initial data
    }, []);

    useEffect(() => {
        if (neededData.length > 0 || availabeData.length > 0) {
            const formattedData = [
                {
                    name: 'Available',
                    type: 'bar',
                    data: neededData.map(item => item.value),
                },
                {
                    name: 'Needed',
                    type: 'bar',
                    data: availabeData.map(item => item.value),
                },
            ];

            const categories = [...new Set([...availabeData, ...neededData].map(item => item.name))]; // Unique categories

            setChartData(formattedData);
            setChartCategories(categories);
        }
    }, [neededData, availabeData]);

    const onChangeChartPeriod = (pType) => {
        fetchRevenueData(pType);
    };

    return (
        <React.Fragment>
            <div className="2xl:p-6 p-2  bg-white rounded-xl border border-gray-300">
                <CardBody className="p-0 pb-2">
                    <div className="w-100">
                        <div className="border-0 align-items-center d-flex ml-5">
                            <h4 className="card-title mb-0 flex-grow-1 mt-2" style={{
                                color: "#495057",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}>{title}</h4>
                            <UncontrolledDropdown className="card-header-dropdown mr-5 mt-2" direction='start'>
                                <DropdownToggle tag="a" className="text-reset dropdown-btn" style={{ textDecoration: 'none' }} role="button">
                                    <span className="text-muted" style={{
                                        color: "#898483",
                                        fontFamily: "Plus Jakarta Sans",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}>Report<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                                    <DropdownItem>Download Report</DropdownItem>
                                    <DropdownItem>Export</DropdownItem>
                                    <DropdownItem>Import</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        <div dir="ltr">
                            <StockInventoryChart series={chartData} dataColors='["--vz-secondary", "--vz-primary", "--vz-primary-rgb, 0.50"]' categories={chartCategories} />
                        </div>
                    </div>
                </CardBody>
            </div>
        </React.Fragment>
    );
}

export default StockInventoryGraph;
