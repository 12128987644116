import React from 'react'
import { Button } from 'reactstrap'
import { RiEqualizerFill } from "react-icons/ri";

function Filterbtn() {
  return (
    <div>
    <Button className="border border-gray-300 bg-white py-2 px-3 transition-all duration-300 hover:bg-gray-100 md:py-3 md:px-4 md:text-lg">
      <RiEqualizerFill className="text-[#726C6C] text-sm 2xl:text-2xl" />
    </Button>
  </div>
  )
}

export default Filterbtn