import React, { useEffect, useState } from 'react'
import CattleDashboardSideBar from '../Common/CattleDashboardSideBar'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import CommonButton from '../Common/Button'
import SearchBox from '../Common/SearchBox'
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import BasicInformation from './Tabs/BasicInformation'
import Vaccination from './Tabs/Vaccination'
import Filterbtn from '../Common/Filterbtn'
import Breeding from './Tabs/Breeding'
import Milking from './Tabs/Milking'
import CommonGraph from '../Common/CommonGraph'
import icon from "../../assets/Images/Frame.svg"
import DietPlan from './Tabs/DietPlan'
import Addcattle from '../Drawer/Addcattle'
import API, { action } from '../../Api'
function CattleDashboard() {
    const style = {
        color: ' #898483',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        padding: '0 5px'
    };
    const [pillsTab, setpillsTab] = useState("1");
    const pillsToggle = (tab) => {
        if (pillsTab !== tab) {
            setpillsTab(tab);
        }
    };

    const [addcattle, setAddcattle] = useState(false);
    const handleclick = () => {
        setAddcattle(!addcattle);
        if (addcattle) {
            setCurrentPage(1); // Reset page to 1
            setCattleList([]); // Clear previous list
            getCattleList(); // Fetch updated list
        }
    };
    const [selectedCattle, setSelectedCattle] = useState({});
    const [cattleList, setCattleList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true); // Track if more data exists

    const handleCattleSelect = (cattleData) => {
        setSelectedCattle(cattleData);
        console.log('Selected Cattle:', cattleData);
    };
console.log(selectedCattle,"selectedCattle");

    // Function to fetch and set cattle list
    const getCattleList = async () => {
        try {
            const result = await action(API.CATTLE_LIST, {
                searchKey: null,
                page: currentPage,
                pageCount: 10
            });

            const newCattleList = result.response.data;

            if (newCattleList.length === 0) {
                setHasMoreData(false); // No data available, stop further API requests
            } else {
                setHasMoreData(true); // There is data, keep fetching more if needed

                // Filter out any duplicate cattle based on cattle_id
                setCattleList(prevList => {
                    const existingCattleIds = new Set(prevList.map(cattle => cattle.cattle_id));
                    const uniqueNewCattle = newCattleList.filter(cattle => !existingCattleIds.has(cattle.cattle_id));
                    return [...prevList, ...uniqueNewCattle]; // Append only unique data
                });
            }
        } catch (error) {
            console.error("Error fetching cattle list:", error);
        }
    };

    useEffect(() => {
        getCattleList(); // Fetch cattle list on component mount or when page changes
    }, [currentPage]);

    const filteredUserList = (cattleList || []).filter(user => {
        const name = user.cattle_id || ""; // Prevent null error
        return name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return (
        // <div>
        //     <CattleDashboardSideBar/>
        // </div>
        <div style={{ backgroundColor: "#FAFAFA",minHeight: "calc(100vh - 135px)"  }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Cattle Dashboard' />
                    <div className="d-flex space-x-5">
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' value={searchTerm}
                            onSearch={setSearchTerm} />
                        <Filterbtn />
                        <CommonButton title='Add Cattle' onClick={handleclick} />
                    </div>
                </div>

                <Row className="gx-3 gy-4">
                    <Col xl={3} >
                        <CattleDashboardSideBar onCattleSelect={handleCattleSelect} cattleList={filteredUserList} page={setCurrentPage} />
                    </Col>
                    <Col xl={9}>


                        <div>

                            <div className="  bg-white rounded-xl border border-gray-300 2xl:p-6 p-4 w-full">
                                {/* {selectedCattle[0].map((cattledata)=>( */}
                                <CardBody>
                                    <Row >
                                        <Col md="6">
                                            <CardTitle style={{
                                                color: '#16A34A',
                                                fontFamily: 'Plus Jakarta Sans',
                                                fontSize: '18px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: '120%',
                                                textTransform: 'capitalize'

                                            }}>
                                                Cattle No. {selectedCattle.cattle_id}</CardTitle>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        {/* <Col>
            <Row>
              <p style={{style}} md="1">Gender</p>
              <p  style={{style}} md="1">Breed</p>
              <p  style={{style}} md="1">Lactation</p>
              <p  style={{style}} md="1">Last Calving</p>
              <p  style={{style}} md="1">Days In Milking</p>
              <p  style={{style}} md="1">Expected Heat</p>
            </Row>
          </Col> */}
                                        <div className="d-flex align-items-center space-x-8" style={{
                                            color: '#898483',
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '120%',
                                            textTransform: 'capitalize'

                                        }}>
                                            <td>
                                                <p className="fs-14 my-1 fw-normal" >Gender</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>{selectedCattle.gender || "--"}</span>
                                            </td>
                                            <td>
                                                <p className="fs-14 my-1 fw-normal">Breed</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>Jersey</span>
                                            </td>
                                            <td>
                                                <p className="fs-14 my-1 fw-normal"
                                                >Lactation</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>{selectedCattle.lactation || "--"}</span>
                                            </td>
                                            <td>
                                                <p className="fs-14 my-1 fw-normal">Last Calving</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>{selectedCattle.last_calving_date || "--"}</span>
                                            </td>
                                            <td>
                                                <p className="fs-14 my-1 fw-normal">Days In Milking</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>{selectedCattle.exp_daily_milk || "--"}</span>
                                            </td>
                                            <td>
                                                <p className="fs-14 my-1 fw-normal">Expected Heat</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>Within 45 Days</span>
                                            </td>

                                        </div>

                                    </Row>

                                    <Row className="mt-3">
                                        <Nav pills className="nav-success mb-3">
                                            {["Basic Information", "Breeding", "Milking", "Vaccination", "Treatments"].map((tab, index) => (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "#0A0B0A",
                                                            backgroundColor: pillsTab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                                            fontFamily: "Plus Jakarta Sans",
                                                            fontSize: "calc(10px + 0.2vw)",
                                                            fontWeight: 600,
                                                            lineHeight: "120%",
                                                            textDecoration: 'none',
                                                            textTransform: 'capitalize'
                                                            // Optional underline for active tab
                                                        }}
                                                        className={pillsTab === String(index + 1) ? "active" : ""}
                                                        onClick={() => pillsToggle(String(index + 1))}
                                                    >
                                                        {tab}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                    </Row>
                                    {pillsTab === "1" && <BasicInformation Selected={selectedCattle}  getCattleList={getCattleList} />}
                                    {pillsTab === "2" && <Breeding Selected={selectedCattle} />}
                                    {pillsTab === "3" && <CommonGraph title="Daily Milk Report" img={icon} rounded='none' Selected={selectedCattle} />}
                                    {pillsTab === "4" && <Vaccination Selected={selectedCattle} />}
                                    {pillsTab === "5" && <DietPlan Selected={selectedCattle} />}
                                </CardBody>
                                {/* ))} */}
                            </div>
                        </div>
                    </Col>
                </Row>

            </FlexCol>
            <Addcattle isOpen={addcattle} toggle={handleclick} />
        </div>
    )
}

export default CattleDashboard