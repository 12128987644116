import React, { useEffect, useState } from 'react';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FlexCol from '../Common/FlexCol';
import { Flex, notification } from 'antd';
import DateSelect from '../Common/DateSelector';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import MultiSelector from '../Common/MultiSelector';
import API, { action } from '../../Api';

function MultipleMilkEntries({ isOpen, toggle, selectedCattle }) {
    const [activeTab, setActiveTab] = useState('Heat');
    const tabs = ['morning', 'evening', 'both'];
    const [cattleid, setCattleid] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const id = localStorage.getItem('userid');
    const [loading, setLoading] = useState(false); 
    console.log(selectedCattle, "selectedCattle");

    const isDisabled = !!selectedCattle;


    // Formik setup
    const formik = useFormik({
        initialValues: {
            milkingSlot: 'morning',  // Default slot is Morning
            milkingDate: '',  // Date picker value
            cattle: selectedCattle && selectedCattle.length > 0 ? [selectedCattle[0].CattleID] : [],
            quantity: '',  // Milk quantity
        },
        validationSchema: Yup.object({
            milkingDate: Yup.date().required('Milking Date is required'),
            cattle: !selectedCattle || selectedCattle.length === 0
                ? Yup.array().min(1, 'Select at least one cattle').required('Cattle selection is required')
                : Yup.array(), // No validation needed if selectedCattle has values
            quantity: Yup.number().required('Quantity is required').positive('Quantity must be greater than zero'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const cattleId = values.cattle.length > 0 ? values.cattle : [selectedCattle[0]?.CattleID];
                await action(API.ADD_MILKING, {
                    cattle_id: cattleId,
                    milking_date: values.milkingDate,
                    quantity: values.quantity,

                    slot: values.milkingSlot,

                    created_by: id
                });
                notification.success({ message: "Record milk added successfully!", placement: "top", });
                // }
                // Optionally reset the form or close the offcanvas
                formik.resetForm();
                toggle(); // Close the offcanvas
            } catch (error) {
                console.error(error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top", });
            } finally {
                setLoading(false); // Stop loading
            }
        },
    });


    const toggle1 = () => {
        formik.resetForm()
        toggle()
    }
    useEffect(() => {
        if (selectedCattle && selectedCattle.length > 0) {
            // Convert milking_date string to Date object
            // Converts string to Date object

            console.log(selectedCattle[0].milking_date, "milkingDate");

            // Set the Date object to Formik values
            formik.setFieldValue("milkingSlot", selectedCattle[0].slot || 'morning');
            formik.setFieldValue("milkingDate", selectedCattle[0].milking_date || ""); // Date object is now set
            formik.setFieldValue("quantity", selectedCattle[0].quantity || '');
        }
    }, [selectedCattle]);

    const getCattleId = async () => {
        try {
            console.log(formik.values.cattle, "lklk");

            const result = await action(API.GET_CATTLE_ID, {
                searchKey: searchKey,
                page: 1,
                pageCount: 20

            });
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id, // Display name
                value: id.cattle_id // Unique identifier
            }));
            setCattleid(cattleID); // Set data with both label and value
        } catch (error) {
            console.error("Error fetching cattle data:", error);
        }
    };

    useEffect(() => {

        getCattleId();

    }, [searchKey]);

    return (
        <Offcanvas isOpen={isOpen} toggle={toggle1} direction="end" className="!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className={"p-4"}>
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Record Milk
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Log Daily Milk Production
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FlexCol className={""} gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                            Milking Slot
                                        </div>
                                        <div className="flex gap-2">
                                            {tabs.map((tab) => (
                                                <div
                                                    key={tab}
                                                    onClick={() => !isDisabled && formik.setFieldValue('milkingSlot', tab)}
                                                    // Update Formik value when tab clicked
                                                    className={`p-2 rounded-lg cursor-pointer ${formik.values.milkingSlot === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                >
                                                    <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words capitalize font-plus-jakarta-sans`}>
                                                        {tab}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Milking Date Picker */}
                                    <DateSelect
                                        title="Milking Date"
                                        placeholder="04 Oct 2024"
                                        value={formik.values.milkingDate}
                                        change={value => formik.setFieldValue('milkingDate', value)}
                                        error={formik.touched.milkingDate && formik.errors.milkingDate}
                                        disabled={isDisabled}
                                        required= {true}
                                    />

                                    {/* Cattle Selector */}
                                    {selectedCattle && selectedCattle.length > 0 ? (
                                        <FormInput
                                            title="Select Cattles"
                                            placeholder="1865"
                                            value={selectedCattle[0].CattleID}
                                            onChange={value => formik.setFieldValue('cattle', value)}
                                            disabled="true"
                                        />
                                    ) : (
                                        <MultiSelector
                                            title="Select Cattles"
                                            placeholder="1865"
                                            options={cattleid}
                                            value={formik.values.cattle}
                                            onSearch={setSearchKey}
                                            onChange={value => {
                                                // Ensure that the value is always in an array format, even when only one option is selected
                                                formik.setFieldValue('cattle', Array.isArray(value) ? value : [value]);
                                            }}
                                            error={formik.touched.cattle && formik.errors.cattle}
                                        />
                                    )}
                                    {/* Quantity Input */}
                                    <FormInput
                                        title="Quantity"
                                        placeholder="5.3 Litre"
                                        type='number'
                                        value={formik.values.quantity}
                                        change={formik.handleChange('quantity')}
                                        error={formik.touched.quantity && formik.errors.quantity}
                                        disabled={isDisabled}
                                        required= {true}
                                    />
                                </Flex>
                            </FlexCol>
                        </FormGroup>

                        <div className="flex justify-between mt-8">
                            <CommonButton
                                style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                                onClick={toggle1}
                                outline
                                title="Discard"
                                disabled={loading}
                            />

                            <CommonButton
                                type="submit"
                                title="Save"
                                style={{ fontSize: "12px" }}
                                disabled={loading}
                            />
                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}

export default MultipleMilkEntries;
