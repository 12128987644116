import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import barchart from "../../assets/Images/Frame.svg"; 
import ci from "../../assets/Images/ci_expand.svg";

const MilkReportChart = ({ cattleData }) => {
  // Get current month (1-based, where 1 is Jan and 12 is Dec)
  const currentMonth = new Date().getMonth() + 1;  // Get current month (0-based index + 1)

  // Extract monthly data from cattleData
  const monthlyData = cattleData?.monthly_report || [];

  // Filter data to show from May (month 5) to current month
  const filteredMonthlyData = monthlyData.filter(item => item.month >= 5 && item.month <= currentMonth);

  // Create an array of months (from May to current month)
  const months = filteredMonthlyData.map(item => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return monthNames[item.month - 1];  // Adjust index for 0-based array
  });

  // Create an array of total liters for each of the filtered months
  const liters = filteredMonthlyData.map(item => item.totalLiters);

  // Chart options configuration
  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
    },
    xaxis: {
      categories: months,  // Use filtered months
      labels: {
        style: {
          colors: '#878787',
          fontSize: '12px',
          fontFamily: 'Plus Jakarta Sans',
          fontWeight: '400',
        },
      },
    },
    yaxis: {
      show: false,  // Remove y-axis labels
    },
    grid: {
      show: false,  // Remove horizontal grid lines
    },
    colors: ['#16A34A'],  // Green color for the bars
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '40%',
      },
    },
    stroke: {
      show: false,  // Remove strokes around bars
    },
    dataLabels: {
      enabled: false,  // Disable data labels
    },
  };

  const chartSeries = [
    {
      name: 'Milk',
      data: liters, // Pass the filtered monthly liters data here
    },
  ];

  return (
    <div className="bg-white rounded-xl border border-gray-300 2xl:p-6 p-3">
      <CardBody>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 rounded mb-3">
              <img src={barchart} alt="Bar chart icon" />
            </div>
            <h2 className="text-sm font-bold text-[#080808] font-plus-jakarta-sans">Monthly Milk Report</h2>
          </div>
          <div className="bg-white rounded-md border border-gray-300 flex items-center w-14 h-7">
            <img src={ci} className="w-7 h-5" alt="expand icon" />
            <p className="ml-1 mt-1">...</p>
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-3 h-3 bg-green-600 rounded" />
          <span className="ml-2 text-gray-800 font-plus-jakarta-sans text-xs">Milk</span>
        </div>

        <Chart options={chartOptions} series={chartSeries} type="bar" height={250} />
      </CardBody>
    </div>
  );
};

export default MilkReportChart;
