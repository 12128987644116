import React, { useState } from 'react'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex } from 'antd'
import FormInput from '../Common/FormInput'
import CommonButton from '../Common/Button'
import MultiSelector from '../Common/MultiSelector'

function NewFeed({isOpen,toggle}) {
    const [activeTab, setActiveTab] = useState('Morning');
    const tabs = ['Morning', 'Evening', 'Both'];
    const options = [
        { label: '1985', value: '1985' },
        { label: '1986', value: '1986' },
        { label: '1987', value: '1987' },
        { label: '1988', value: '1988' },
      ];
      const [selectedCountries, setSelectedCountries] = useState(['china']); // Default selected value

      const handleChange = (value) => {
        setSelectedCountries(value); // Update selected countries
      };
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} direction="end"  className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"    >
            <FlexCol className={"p-4"} >

                <OffcanvasHeader >
                    <div >
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B]  leading-[21.6px] break-words font-plus-jakarta-sans">Create Feed Group</div>
                        <div className="text-[10px] 2xl:text-sm   text-[#726C6C] font-plus-jakarta-sans ">Add details for grouping.</div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody >
                    <FormGroup>
                        <FlexCol className={""} gap={20}>
                            <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                    Feeding Slot
                                    </div>
                                    <div className="flex gap-2">

                                        {tabs.map((tab) => (
                                            <div
                                                key={tab}
                                                onClick={() => setActiveTab(tab)}
                                                className={`p-2 rounded-lg cursor-pointer ${activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                            >
                                                <div className={`text-[#0A0B0A] text-[12px] md:text-[10px]  font-medium leading-[16.8px] break-words`}>
                                                    {tab}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                               
                                
                                <FormInput title='Feed Group Name  ' placeholder='Q1 TMR'  />
                                <FormInput title='Feed Items  ' placeholder='Q1 Regular'  />
                                <MultiSelector title='Select Cattles  ' placeholder='1865 ' options={options} handleChange={handleChange} value={selectedCountries}  />
                                <FormInput title='Quantity  ' placeholder='300 gm'  />
                              

                            </Flex>
                        </FlexCol>
                    </FormGroup>
                    <div className=" flex justify-between mt-8 ">
                        <CommonButton
                            style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                            onClick={toggle}
                            outline
                            title='Discard'
                        />



                        <CommonButton title='Add Feed' style={{ fontSize: "12px" }} />


                    </div>
                </OffcanvasBody>

            </FlexCol>
        </Offcanvas>
  )
}

export default NewFeed