import React, { useEffect, useState } from 'react';
import FlexCol from '../Common/FlexCol';
import CommonButton from '../Common/Button';
import Cards from '../Common/Cards';
import { Col, Row } from 'reactstrap';
import RecordMilk from '../Common/RecordMilk';
import YieldingCattle from '../Common/YieldingCattle';
import MilkReportChart from './MilkReportChart';
import DailyMilkReport from './DailyMilkReport';
import MilkingStatistics from './MilkingStatistics';
import Heading from '../Common/Heading';
import API, { action } from '../../Api';
import { useNavigate } from 'react-router';

function MilkReport() {
  const [showMilkingStats, setShowMilkingStats] = useState(false);
  const [cattledata,setCattledata] = useState([])
  const [dashboarddata,setDashboarddata] = useState([])
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/milkReport/milkingStatistics")
  };
 const cardClick =()=>{
  navigate("/milkReport/milkingStatistics")
 }
 const oncardclick = () => {
  navigate("/milkReport/milkingStatistics")
}
  const getTopCattleData = async () => {
    const result = await action(API.GET_TOP_CATTLE_DATA, {
     
    });
    console.log(result.response.data, 'get upcomming list');
    setCattledata(result.response.data);
  };
  
  useEffect(() => {
    getTopCattleData();
  }, []);


  const getMilkDashboardData = async () => {
    const result = await action(API.GET_MILK_DASHBOARD_DATA, {
     
    });
    console.log(result.response.data, 'get dashboard data');
    setDashboarddata(result.response.data);
  };
  
  useEffect(() => {
    getMilkDashboardData();
  }, []);


  const carddata = [
    {
        id: 1,
        cardColor: "primary",
        label: "Total Milking",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+16.24",
        counter: dashboarddata.total_milk,
        link: "View net earnings",
        bgcolor: "primary",
        icon: "bx bx-dollar-circle",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
    {
        id: 2,
        cardColor: "secondary",
        label: "Pending Records",
        badge: "ri-arrow-right-down-line",
        badgeClass: "danger",
        percentage: "-3.57",
        counter: dashboarddata.pending_record || 0,
        link: "View all orders",
        bgcolor: "primary",
        icon: "bx bx-shopping-bag",
        decimals: 0,
        prefix: "",
        separator: ",",
        suffix: ""
    },
    {
        id: 3,
        cardColor: "success",
        label: "Not Usable",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+29.08",
        counter: dashboarddata.not_usable || 0,
        link: "See details",
        bgcolor: "primary",
        icon: "bx bx-user-circle",
        decimals: 2,
        prefix: "",
        suffix: "M"
    },
    {
        id: 4,
        cardColor: "info",
        label: "Dry Cattle",
        badgeClass: "muted",
        percentage: "+0.00",
        counter: dashboarddata.dry_cattles,
        link: "Withdraw money",
        bgcolor: "primary",
        icon: "bx bx-wallet",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
  ]

  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <FlexCol>
        {!showMilkingStats ? ( // Conditional rendering
          <>
            <div className="d-flex justify-content-between">

              <Heading title='Milk Report' />
              <div className="d-flex space-x-5">
                <CommonButton
                  title="View All Records"
                  style={{ color: "#0EA5E9", backgroundColor: "none", border: "1px #0EA5E9 solid" }}
                  onClick={handleButtonClick} // Add onClick handler
                />
              </div>
            </div>

            <Cards data={carddata} cardClick={cardClick} />
            <Row className="gx-3 gy-4">
              <Col xl={8}>
                <div >
                  <DailyMilkReport cattleData={dashboarddata} />
                </div>
              </Col>
              <Col xl={4}>
                <RecordMilk  />
              </Col>
              <Row className="gx-3 gy-3">
                <Col xl={5}>
                  <MilkReportChart cattleData={dashboarddata} />
                </Col>
                <Col xl={7}>
                  <YieldingCattle  cattleData={cattledata} onCardClick={oncardclick} />
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          <MilkingStatistics /> // Show MilkingStatistics when button is clicked
        )}
      </FlexCol>
    </div>
  );
}

export default MilkReport;
