import React from 'react';
import { CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import CommonButton from './Button';

function Reportsbarchart({ title, subtitle, bgcolor ,handleclick,btnbgcolor,btntitle,titleColor }) {
  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      labels: {
        style: {
          colors: '#878787', // Set the color of month names
          fontSize: '12px',  // Set the font size
          fontFamily: 'Plus Jakarta Sans', // Set the font family
          fontWeight: '400', // Set the font weight
        },
      },
    },
    yaxis: {
      show: false, // This line removes the y-axis labels
    },
    grid: {
      show: false, // Remove horizontal grid lines
    },
    colors: bgcolor,
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '40%',
      },
    },
    stroke: {
      show: false, // This line removes the strokes
    },
    dataLabels: {
      enabled: false, // This line disables data labels
    },
  };

  const chartSeries = [
    {
      name: 'Milk',
      data: [123, 74, 54, 25, 63, 144, 158], // Replace with your data
    },
  ];

  return (
    <div className="2xl:p-6 p-12 w-full h-full relative bg-white rounded-xl border-1 border-[#E3E3E3]">
      <CardBody>
        <div className="flex flex-col items-center mb-4 text-center">
        <h2 className="text-xl 2xl:text-2xl font-bold font-plus-jakarta-sans" style={{ color: titleColor }}>{title}</h2>
          {subtitle && <h4 className="text-xs text-[#726C6C] mt-1 font-plus-jakarta-sans">{subtitle}</h4>}
        </div>

        <Chart options={chartOptions} series={chartSeries} type="bar" height={200} />

        <div className="flex justify-center ">
          <div className="flex flex-col items-center w-full">
            <CommonButton
              title={btntitle}
              style={{
                backgroundColor: btnbgcolor,
                fontSize: "10px",
                width: "300px",
                height: "2.25rem", // h-9 is 2.25rem
                paddingLeft: "1.5rem", // px-6 is 1.5rem
                paddingRight: "1.5rem",
                paddingTop: "0.5rem", // py-2 is 0.5rem
                paddingBottom: "0.5rem",
                ...(window.innerWidth >= 640 && { paddingLeft: "1.75rem", paddingRight: "1.75rem" }) // sm:px-7 is 1.75rem
              }}
              onClick={handleclick}
            />
          </div>
        </div>
      </CardBody>
    </div>
  );
}

export default Reportsbarchart;
