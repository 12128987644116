import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';
import API, { action } from '../../Api';

const PieChart = ({ title }) => {
  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]); // To hold the series data
  const [labels, setLabels] = useState([]); // To hold the labels

  const getPiedata = async () => {
    try {
      const result = await action(API.GET_MONTHLY_DIAGNOSE_COUNT_COUNT, {});
      console.log(result.response.data, "get pie data");

      // Extract data from API response
      const percentages = result.response.data.percentages;
      const seriesData = [];
      const labelsData = [];

      // Add the percentages and corresponding labels dynamically
      Object.keys(percentages).forEach(key => {
        seriesData.push(percentages[key]);
        
        // Split the key into words and add "and" between them if there are two words
        const words = key.replace(/([a-z])([A-Z])/g, '$1 $2').split(' '); // Add space before uppercase letters
        if (words.length === 2) {
          // If there are exactly two words, add "and" between them
          labelsData.push(`${words[0]} and ${words[1]}`);
        } else {
          // Otherwise, just capitalize the first word and join them
          labelsData.push(words.join(' '));
        }
      });

      setSeries(seriesData);
      setLabels(labelsData);
    } catch (error) {
      console.error("Error fetching pie data:", error);
    }
  };

  useEffect(() => {
    getPiedata();
  }, []);

  const options = {
    chart: {
      height: 300,
      type: 'pie',
    },
    labels: labels, // Use the dynamic labels
    legend: {
      position: 'right',
      
    fontFamily: 'Plus Jakarta Sans',
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return val.toFixed(0) + "%"; // Display the percentage
      },
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['#fff'], // Label color
        fontFamily: "Plus Jakarta Sans",
        width:50,
        height:50,
        padding:20,
      },
      background: {
        enabled: true,
        foreColor: '#000',
        // marginTop: 10,
        // marginLeft: 10,
        borderRadius: '50%', // Make the background circular
        borderWidth: 1,
        borderColor: '#fff',
        opacity: 1,
      },
      dropShadow: {
        enabled: true,
        top: 2,
        left: 2,
        blur: 4,
        opacity: 0.25,
      },
      offsetY: -20, // Move the label upwards
      offsetX: 0,
      
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 30, // Adjust this to move the labels outside of the pie
        },
      },
    },
    fill: {
      type: 'solid',
    },
  };

  return (
    <React.Fragment>
      <div className="2xl:p-6 p-2 bg-white rounded-xl border border-gray-300">
        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div className="border-0 align-items-center d-flex ml-5">
              <h4
                className="card-title mb-0 flex-grow-1 mt-2 2xl:text-[20px] text-[14px]"
                style={{
                  color: "#495057",
                  fontFamily: "Plus Jakarta Sans",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                {title}
              </h4>
            </div>
            <div className="chart-container" style={{ position: "relative", marginTop: "30px" }}>
              <ReactApexChart
                dir="ltr"
                className="apex-charts font-plus-jakarta-sans text-#767676"
                series={series} // Use dynamic series
                options={options}
                type="donut"
                height={370}
                
              />
            </div>
          </div>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default PieChart;
