import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import FlexCol from "../Common/FlexCol";
import CommonButton from "../Common/Button";
import FormInput from "../Common/FormInput";
import { Flex, notification } from "antd";
import TextArea from "../Common/TextArea";
import DateSelect from "../Common/DateSelector";
import { useFormik } from "formik";
import * as Yup from "yup";
import API, { action } from "../../Api";
import DropdownButton from "../Common/DropdownButton";

function DiagnoseCattle({ isOpen, toggle, selectedData }) {
  console.log(selectedData, "selectedData");
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);

  const [medicines, setMedicines] = useState([
    { name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" },
  ]); // Change slot to an array
  const [cattleid, setCattleid] = useState([]);
  const tabs = ["Foot & Mouth", "Other"];
  const medicineNeededOptions = ["Yes", "No"];
  const medicineSlotOptions = ["Any","Morning", "Noon", "Evening"];
  const isDisabled = !!selectedData;
  const isdisabled = !!selectedData?.[0]?.cured_notes;
  console.log(selectedData?.[0]?.cured_notes, "cured");

  const handleAddMedicine = () => {
    const newMedicine = {
      name: "",
      dosage: "",
      slots:  ["Any"],
      startDate: "",
      endDate: "",
    }; // Change slot to an array
    setMedicines([...medicines, newMedicine]);
    formik.setFieldValue("medicines", [...medicines, newMedicine]);
  };

  const handleMedicineChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };

  const handleSlotChange = (index, slot) => {
    const updatedMedicines = [...medicines];
    let currentSlots = updatedMedicines[index].slots || [];
  
    if (slot === "Any") {
      // If "Any" is selected, clear all other slots
      currentSlots = currentSlots.includes("Any") ? [] : ["Any"];
    } else {
      // If another slot is selected, remove "Any" and toggle the current slot
      currentSlots = currentSlots.filter((s) => s !== "Any");
      if (currentSlots.includes(slot)) {
        currentSlots = currentSlots.filter((s) => s !== slot); // Deselect the slot
      } else {
        currentSlots.push(slot); // Select the slot
      }
    }
  
    updatedMedicines[index].slots = currentSlots;
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };
  

  const formik = useFormik({
    initialValues: {
      issueType: "Foot & Mouth",
      issueDate: "",
      cattleId: "",
      severity: "",
      medicineNeeded: "No",
      modeOfAdministration: "",
      medicines: medicines,
      other: "",
    },
    validationSchema: Yup.object({
      issueType: Yup.string().required("Issue type is required"),
      issueDate: Yup.string().required("Issue date is required"),
      cattleId: Yup.string().required("Cattle id is required"),
      severity: Yup.string().required("Severity is required"),
      medicineNeeded: Yup.string().required("Required"),
      // other: Yup.string().when('issueType', {
      //   is: 'Other',
      //   then: () => Yup.string().required('Other issue is required'),
      //   otherwise: () => Yup.string().notRequired(),
      // }),
      // modeOfAdministration: Yup.string().required("Required").max(200, "Description is too long"),
      medicines: Yup.array().when('medicineNeeded', {
        is: 'Yes',
        then: () => Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .required('Medicine name is required'),
            dosage: Yup.string()
              .required('Dosage is required'),
            // slots: Yup.string()
            //   .required('Medicine slot is required'),
            startDate: Yup.string()
              .required('Start date is required'),
            endDate: Yup.string()
              .required('End date is required')
              .test('date-validation', 'End date must be after start date',
                function (value) {
                  const { startDate } = this.parent;
                  if (!startDate || !value) return true;
                  return new Date(value) > new Date(startDate);
                }),
          })
        ).min(1, 'At least one medicine is required'),
        otherwise: () => Yup.array()
      }),
    }),
    onSubmit: async (values) => {
      // console.log(values, "values");
      if (selectedData && selectedData.length > 0) {
        formik2.handleSubmit(); // Submit formik2
      } else {
        setLoading(true);
        try {

          // Map the medicines to the API format
          const formattedMedicines = values.medicines.map((medicine) => ({
            start_date: medicine.startDate,
            end_date: medicine.endDate,
            mode_of_administration: values.modeOfAdministration, // Added from form values
            medicine_name: medicine.name,
            dosage: medicine.dosage,
            //   slot: medicine.slots.join(", "),
            slot: medicine.slots,
          }));

          const result = await action(API.ADD_DIAGNOSIS, {
            issue_type: values.issueType,
            issue_date: values.issueDate,
            cattle_id: values.cattleId,
            severity: values.severity,
            need_medicine: values.medicineNeeded,
            created_by: 1, // Replace with actual user ID if available
            medicines: formattedMedicines,
            other_issue: values.other
          });
          notification.success({
            message: result?.response?.message,
            placement: "top",
          });
          formik.resetForm();
          setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
          toggle(); // Close the offcanvas
        } catch (error) {
          console.error("Error creating diagnosis:", error);
          notification.error({
            message: error.response?.data?.response?.message,
            placement: "top",
          });
        } finally {
          setLoading(false); // Stop loading
        }
      }
    },
  });

  console.log(formik.values, "-------------------");

  const customToggle = () => {
    // setId(null);
    formik.resetForm(); // Set id to null
    setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
    toggle(); // Call the original toggle function
  };
  const severityOptions = [
    { value: "critical", label: "Critical" },
    { value: "normal", label: "Normal" },
    { value: "major", label: "Major" },
  ];

  const getCattleId = async () => {
    try {
      const result = await action(API.GET_CATTLE_ID, {
        searchKey: searchKey,
        page: 1,
        pageCount: 10,
      });
      const cattleID = result.response.data.map((id) => ({
        label: id.cattle_id, // Display name
        value: id.cattle_id, // Unique identifier
      }));
      setCattleid(cattleID); // Set data with both label and value

      console.log(cattleID); // Check if data is in correct format
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    getCattleId();
    console.log(cattleid, "cattleid");
  }, [searchKey]);


  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      // Set basic fields
      formik.setFieldValue("issueDate", selectedData[0]?.issue_date);
      formik.setFieldValue("severity", selectedData[0]?.severity);
      formik.setFieldValue("cattleId", selectedData[0]?.CattleID);
      formik.setFieldValue("issueType", selectedData[0]?.issue_type);
      formik.setFieldValue("medicineNeeded", selectedData[0]?.need_medicine);
      formik.setFieldValue("modeOfAdministration", selectedData[0]?.mode_of_administration);
      formik.setFieldValue("modeOfAdministration", selectedData[0]?.mode_of_administration);
      formik.setFieldValue("slot", selectedData[0]?.slot);
      formik2.setFieldValue("curednotes", selectedData[0]?.cured_notes);
      formik2.setFieldValue("cureddate", selectedData[0]?.cured_date);

      // Set medicines
      const medicinesFromData = selectedData.map((data) => ({
        name: data?.medicine_name || "",
        dosage: data?.dosage || "",
        slots: data?.slots || [],
        startDate: data?.medication_date || "",
        endDate: data?.end_date || "",
      }));
      setMedicines(medicinesFromData);
      formik.setFieldValue("medicines", medicinesFromData);

      console.log(selectedData[0]?.CattleID, "Selected Data ID");
    }
  }, [selectedData]);

  console.log(selectedData, "hhh");



  const formik2 = useFormik({
    initialValues: {
      id: "",
      curednotes: "",
      cureddate: "",
    },
    validationSchema: Yup.object({
      curednotes: Yup.string().required("Required"),
      cureddate: Yup.string().required("Required"),

    }),
    onSubmit: async (values) => {
      setLoading(true);
      console.log(values, "values");
      try {


        const result = await action(API.UPDATE_DIAGNOSIS_STATUS, {
          id: selectedData[0]?.id,
          cured_notes: values.curednotes,
          cured_date: values.cureddate,

        });
        notification.success({
          message: result?.response?.message,
          placement: "top",
        });
        formik2.resetForm();
        setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
        toggle(); // Close the offcanvas
      } catch (error) {
        console.error("Error creating diagnosis:", error);
        notification.error({
          message: error.response?.data?.response?.message,
          placement: "top",
        });
        // Handle the error as needed
      } finally {
        setLoading(false); // Stop loading
      }
    },
  });

  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={customToggle}
      direction="end"
      className="!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
    >
      <FlexCol className="p-4">
        <OffcanvasHeader>
          <div>
            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
              Diagnose Cattle
            </div>
            <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
              Enter symptoms and treatment.
            </div>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <FlexCol gap={20}>
                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                  <div
                    style={{
                      flexDirection: "column",
                      gap: 14,
                      display: "flex",
                    }}
                  >

                    {selectedData ? (
                      <FormInput
                        title="Issue Type"
                        placeholder="Issue Type"
                        value={formik.values.issueType}
                        change={(e) => formik.setFieldValue("issueType", e)}
                        error={formik.touched.issueType && formik.errors.issueType}
                        disabled={isDisabled}
                        required={true}
                      />
                    ) : (

                      <>

                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                          Issue Type
                        </div>
                        <div className="flex gap-2">
                          {tabs.map((tab) => (
                            <div
                              key={tab}
                              onClick={() => !isDisabled && formik.setFieldValue("issueType", tab)}

                              className={`p-2 rounded-lg cursor-pointer ${formik.values.issueType === tab
                                ? "bg-[#E3E3E3]"
                                : "bg-transparent"
                                } flex justify-center items-center`}
                            >
                              <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                                {tab}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  {formik.values.issueType === "Other" && (
                    <FormInput
                      title="Other Issues"
                      placeholder="Other Issues"
                      value={formik.values.other}
                      change={(e) => formik.setFieldValue("other", e)}
                      error={formik.touched.other && formik.errors.other}
                      disabled={isDisabled}
                      required={true}
                    />
                  )}
                  <DateSelect
                    title="Issue Date"
                    placeholder="Issue Date"
                    value={formik.values.issueDate}
                    change={(e) => formik.setFieldValue("issueDate", e)}
                    error={formik.touched.issueDate && formik.errors.issueDate}
                    disabledFutureDate={true}
                    disabled={isDisabled}
                    required={true}
                  />

                  <DropdownButton
                    title="Cattle ID"
                    placeholder="1865"
                    value={formik.values.cattleId}
                    options={cattleid}
                    onSearch={setSearchKey}
                    change={(e) => formik.setFieldValue("cattleId", e)}
                    error={formik.touched.cattleId && formik.errors.cattleId}
                    disabled={isDisabled}
                    required={true}
                  />

                  <DropdownButton
                    title="Severity"
                    placeholder="Critical"
                    value={formik.values.severity}
                    options={severityOptions}
                    change={(e) => formik.setFieldValue("severity", e)}
                    error={formik.touched.severity && formik.errors.severity}
                    disabled={isDisabled}
                    required={true}
                  />
                  {(!selectedData || selectedData.length === 0) && (
                    <div
                      style={{
                        flexDirection: "column",
                        gap: 14,
                        display: "flex",
                      }}
                    >
                      <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                        Need Medicine
                      </div>
                      <div className="flex gap-2">
                        {medicineNeededOptions.map((option) => (
                          <div
                            key={option}
                            onClick={() =>
                              formik.setFieldValue("medicineNeeded", option)
                            }
                            className={`p-2 rounded-lg cursor-pointer ${formik.values.medicineNeeded === option
                              ? "bg-[#E3E3E3]"
                              : "bg-transparent"
                              } flex justify-center items-center`}
                          >
                            <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                              {option}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {formik.values.medicineNeeded === "Yes" &&

                    medicines.map((medicine, index) => (
                      <div
                        key={index}
                        style={{
                          flexDirection: "column",
                          gap: 20,
                          display: "flex",
                        }}
                      >
                        <FormInput
                          title="Medicine Name"
                          placeholder="CC REGULAR MEDICINE"
                          value={medicine.name}
                          change={(e) => handleMedicineChange(index, "name", e)}
                          error={
                            formik.errors.medicines &&
                            formik.errors.medicines[index]?.name
                          }
                          disabled={isDisabled}
                          required={true}
                        />
                        <FormInput
                          title="Dosage"
                          placeholder="2.5 mg"
                          type="number"
                          value={medicine.dosage}
                          change={(e) => handleMedicineChange(index, "dosage", e)}
                          error={
                            formik.errors.medicines &&
                            formik.errors.medicines[index]?.dosage
                          }
                          disabled={isDisabled}
                          required={true}
                        />
                        {(!selectedData || selectedData.length === 0) && (
                          <>
                           <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
  Medicine Slot
</div>
<div className="flex gap-2">
  {medicineSlotOptions.map((slot) => (
    <div
      key={slot}
      onClick={() => handleSlotChange(index, slot)}
      className={`p-2 rounded-lg cursor-pointer ${
        medicine.slots.includes(slot) ? "bg-[#E3E3E3]" : "bg-transparent"
      } flex justify-center items-center`}
    >
      <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
        {slot}
      </div>
    </div>
  ))}
</div>

                          </>
                        )}
                        <DateSelect
                          title="Start Date"
                          placeholder="Start Date"
                          value={medicine.startDate}
                          change={(e) =>
                            handleMedicineChange(index, "startDate", e)
                          }
                          error={
                            formik.errors.medicines &&
                            formik.errors.medicines[index]?.startDate
                          }
                          disabled={isDisabled}
                          required={true}
                          disableBeforeDate={formik.values.issueDate || ""}
                        />
                        <DateSelect
                          title="End Date"
                          placeholder="End Date"
                          value={medicine.endDate}
                          change={(e) =>
                            handleMedicineChange(index, "endDate", e)
                          }
                          error={
                            formik.errors.medicines &&
                            formik.errors.medicines[index]?.endDate
                          }
                          disabled={isDisabled}
                          required={true}
                          disableBeforeDate={formik.values.medicines[index]?.startDate || ""}
                        />
                      </div>
                    ))}
                  {formik.values.medicineNeeded === "Yes" && (
                    <>
                      {(!selectedData || selectedData.length === 0) && (
                        <CommonButton title="Add Medicine" onClick={handleAddMedicine} />
                      )}


                    </>
                  )}


                  <TextArea
                    title="Mode of Administration"
                    placeholder="mode of administration here..."
                    value={formik.values.modeOfAdministration}
                    change={(e) =>
                      formik.setFieldValue("modeOfAdministration", e)
                    }
                    error={
                      formik.touched.modeOfAdministration &&
                      formik.errors.modeOfAdministration
                    }
                    disabled={isDisabled}

                  />


                  {selectedData && selectedData.length > 0 && (
                    <>
                      <DateSelect
                        title="Cured Date"
                        placeholder="Cured Date"
                        value={formik2.values.cureddate || ""}
                        change={(e) => formik2.setFieldValue("cureddate", e)}
                        error={
                          formik2.touched.cureddate &&
                          formik2.errors.cureddate
                        }
                        disableBeforeDate={formik.values.issueDate}
                        disabled={isdisabled}
                        required={true}
                      />
                      <TextArea
                        title="Cured Notes"
                        placeholder="Cured Notes"
                        value={formik2.values.curednotes || ""}
                        change={(e) => formik2.setFieldValue("curednotes", e)}
                        error={
                          formik2.touched.curednotes &&
                          formik2.errors.curednotes
                        }
                        disabled={isdisabled}

                      />

                    </>
                  )}
                </Flex>
              </FlexCol>
            </FormGroup>
            <Col className="flex justify-end mt-5 gap-2">
              {!selectedData?.[0]?.cured_notes && (
                <CommonButton title="Submit" type="submit" disabled={loading} />
              )}
              <CommonButton title="Cancel" type="button" onClick={toggle} disabled={loading} />
            </Col>
          </form>
        </OffcanvasBody>
      </FlexCol>
    </Offcanvas>
  );
}

export default DiagnoseCattle;
