import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import CommonButton from '../../Common/Button';
import API, { action } from '../../../Api';

function Breeding(Selected) {
console.log(Selected.Selected?.cattle_id
  ,"selectedcvc");

const [breedingList,setBreedingList]=useState([])

  const colorCombinations = [
    { text: '#C98F02', background: '#FEEAB780' },
    { text: '#0A74A3', background: '#ACE2FA80' },
    { text: '#21055E', background: '#E6DBFD' },
    { text: '#0B4F24', background: '#DBFAE780' },
  ];

  const cardData = [
    { days: 18, title: 'Insemination', date: '06 Sep 2024', description: 'Artificial insemination' },
    { days: 20, title: 'Insemination', date: '08 Sep 2024', description: 'Natural insemination' },
    { days: 15, title: 'Insemination', date: '10 Sep 2024', description: 'Frozen semen' },
    { days: 14, title: 'Insemination', date: '06 Sep 2024', description: 'Artificial insemination' },
    { days: 10, title: 'Insemination', date: '08 Sep 2024', description: 'Natural insemination' },
    { days: 16, title: 'Insemination', date: '10 Sep 2024', description: 'Frozen semen' },
    { days: 14, title: 'Insemination', date: '06 Sep 2024', description: 'Artificial insemination' },
    { days: 10, title: 'Insemination', date: '08 Sep 2024', description: 'Natural insemination' },
    { days: 16, title: 'Insemination', date: '10 Sep 2024', description: 'Frozen semen' },
    // Add more cards as needed
  ];

  const getBreedingList = async () => {
    try {
        const result = await action(API.GET_CATTLE_BREEDING_DATA, {
          cattle_id:Selected.Selected.cattle_id,
            page: 1,
            pageCount: 10
        });
        setBreedingList(result.response.data);
    } catch (error) {
        console.error("Error fetching cattle list:", error);
    }
};

useEffect(() => {

    getBreedingList();  // Fetch cattle list if cattle_id is present
  
}, [Selected])

return (
  <div className="basic-info-container">
    <div className="flex justify-between">
      <p className="text-[#16A34A] font-plus-jakarta-sans text-lg font-semibold leading-6">
        Breeding
      </p>
      {/* <CommonButton title="Add New State" style={{ backgroundColor: "#E3E3E3", color: "#2B2928", fontSize: "10px", fontFamily: "Plus Jakarta Sans" }} /> */}
    </div>

    {/* Conditionally render "No Data" or breeding cards */}
    {breedingList.length === 0 ? (
      <div className="text-center text-gray-500 mt-4 font-plus-jakarta-sans">
        <p className='font-plus-jakarta-sans text-sm'>No data available</p>
      </div>
    ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-4">
        {breedingList.map((data, index) => {
          const { background, text } = colorCombinations[index % colorCombinations.length];
          return (
            <Card key={index} className="border border-gray-300 rounded-lg w-full max-w-xs">
              <CardBody className="flex space-x-4 p-3">
                {/* Left side small card */}
                <div className={`rounded-md flex flex-col justify-center items-center p-2 gap-4`} style={{ backgroundColor: background }}>
                  <div className="text-lg md:text-sm font-bold text-center" style={{ color: text }}>
                    {data.days} <br /> Days
                  </div>
                </div>

                {/* Right side text */}
                <div className="flex flex-col justify-center items-start gap-1">
                  <div className="text-gray-900 text-sm font-bold">
                    Insemination
                  </div>
                  <div className="text-gray-600 text-xs">
                    {data.insemination_date}
                  </div>
                  <div className="text-gray-600 text-xs">
                    {data.insemination_type}
                  </div>
                </div>
              </CardBody>
            </Card>
          );
        })}
      </div>
    )}
  </div>
);
}

export default Breeding;
