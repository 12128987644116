import React, { useEffect, useState } from "react";
import { PiBellFill, PiClock } from "react-icons/pi";
import { MdNotificationsNone } from "react-icons/md";

const Notification = () => {
  const [show, setShow] = useState(false);

  const [requestTodayLength, setRequestTodayLength] = useState(66);

  const [selectedId, setSelectedId] = useState(3);

  const handleClick = () => {
    setShow(true);
  };

  const handleData = (id) => {
    setSelectedId(id);
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    setCurrentTime(new Date());
  }, []);

  return (
    <>
      <div
        className="relative flex items-center justify-center w-8 h-8 rounded-full cursor-pointer 2xl:w-10 2xl:h-10 ltr:mr-4 rtl:ml-4 bg-secondaryWhite dark:bg-secondaryDark"
        title="nofications"
        onClick={handleClick}
      >
        <MdNotificationsNone
          size={25}
          className="text-sm text-black 2xl:text-base opacity-70  dark:opacity-100"
        />
        {requestTodayLength !== 0 && (
          <div className="absolute top-0 right-0 w-3 h-3 text-white rounded-full 2xl:w-4 2xl:h-4 bg-primary vhcenter">
            <p className="text-[8px] 2xl:text-xs font-semibold">
              {requestTodayLength}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
