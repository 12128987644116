import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Dropdown, Menu, Avatar } from 'antd';
import { DownOutlined, UserOutlined, BellOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';

import avatar1 from "../../assets/Images/Avatar.png";

const ProfileDropdown = () => {

    const [userName, setUserName] =  useState(
        (localStorage.getItem("name")) );
        const [email,setEmail]= useState(
            (localStorage.getItem("email")) );

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const navigate = useNavigate();

    // Logout function
    const handleLogout = () => {
        localStorage.removeItem("usertype"); // Clear userType from localStorage
        navigate("/"); // Redirect to login page
        window.location.reload();
    };

    const handleaccountsettings = () => {
        navigate('/accountSettings')
    }
     // useNavigate() can be used because this is inside <BrowserRouter>

   
    
    // Function to check token expiration
    const checkTokenExpiration = () => {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      
      if (tokenExpiration) {
        const currentTime = Date.now();
        if (currentTime > tokenExpiration) {
          // Token expired, perform logout
          localStorage.removeItem('usertype');
          localStorage.removeItem('name');
          localStorage.removeItem('email');
          localStorage.removeItem('mobile');
          localStorage.removeItem('userid');
          localStorage.removeItem('modulePrivileges');
          localStorage.removeItem('tokenExpiration');
          
          // Redirect to login page
          navigate("/");
        }
      }
    };
  
    // Set a timer to log the user out before the token expires
    const setLogoutTimer = () => {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      
      if (tokenExpiration) {
        const timeout = tokenExpiration - Date.now() - 1000; // 1 second before expiration
        if (timeout > 0) {
          setTimeout(() => {
            // Token expired, perform logout
            localStorage.removeItem('usertype');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('mobile');
            localStorage.removeItem('userid');
            localStorage.removeItem('modulePrivileges');
            localStorage.removeItem('tokenExpiration');
            
            // Redirect to login page
            navigate("/");
            window.location.reload();
            
          }, timeout);
        }
      }
    };
  
    useEffect(() => {
      // Check token expiration on app load
      checkTokenExpiration();
      // Set the logout timer
      setLogoutTimer();
     
    }, [navigate]);

    const menu = (
      <Menu className='font-plus-jakarta-sans'>
        <Menu.Item key="welcome" disabled>
          <span>Welcome {userName}!</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="profile" onClick={() => navigate('/profile')} icon={<UserOutlined />}>
          Profile
        </Menu.Item>
        <Menu.Item key="settings" onClick={handleaccountsettings} icon={<SettingOutlined />}>
          Account Settings
        </Menu.Item>
        <Menu.Item key="notifications" icon={<BellOutlined />}>
          Notification Settings
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    );
  
    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className="flex items-center cursor-pointer">
        <Avatar src={avatar1} size="large" />
        <div className="hidden lg:block text-left ml-2">
          <span className="text-[#09090B] text-[14px] font-[500] font-plus-jakarta-sans block">{userName}</span>
          <span className="text-[#71717A] text-[12px] font-[400] font-plus-jakarta-sans">{email}</span>
        </div>
        <DownOutlined className="ml-2 text-xs" />
      </div>
    </Dropdown>
    );
  };
  export default ProfileDropdown;