import React, { useState } from 'react';
import { Collapse } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoPencil } from 'react-icons/go';
import { MdOutlineLock } from 'react-icons/md';

const { Panel } = Collapse;

function Accordion({ headings, data, handleAction, handlePassword, handleToggle, handleEdit }) {
  const [activeKey, setActiveKey] = useState([]);

  const onChange = (key) => {
    setActiveKey(key);
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return text;
    return text.replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <div className="accordion-wrapper">
      <Collapse
        activeKey={activeKey}
        onChange={onChange}
        accordion
        expandIconPosition="right"
      >
        {data.map((item, index) => (
          <Panel
            header={`Cattle No. ${item.cattleNumber}`} // Use the appropriate field for the header
            key={index}
            style={{ backgroundColor: 'white', borderBottom: '1px solid #e8e8e8' }}
          >
            <div className="accordion-content">
              {headings.map((heading) => {
                const value = item[heading.value];

                if (heading.title === 'Action') {
                  return (
                    <div key={heading.id}>
                      <button
                        onClick={() => handleEdit(item)}
                        className="btn btn-edit">
                        <GoPencil /> Edit
                      </button>
                      <button
                        onClick={() => handlePassword(item.userId)}
                        className="btn btn-password">
                        <MdOutlineLock /> Change Password
                      </button>
                      <button className="btn btn-view">
                        <BsThreeDotsVertical /> View Details
                      </button>
                    </div>
                  );
                } else {
                  return (
                    <div key={heading.id}>
                      <strong>{capitalizeFirstLetter(heading.title)}: </strong>
                      <span>{value || "--"}</span>
                    </div>
                  );
                }
              })}
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default Accordion;
